
import { apiRoutes } from "../constants/config";
import firebase from "./firebase"
import { getAuthHeader, cacheRequest } from "./api";

export const login = async (email, password, houseKey) => {
    try {

        let user = null;
        try {
            user = await firebase.auth().signInWithEmailAndPassword(email, password)
            const idToken = await user.getIdToken();
            localStorage.setItem("userToken", idToken)

            let headers = new Headers()
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${idToken}`)
            let init = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    email
                })
            };

            let response = await fetch(apiRoutes.loginValidation, init)
            let jsonRes = await response.json();
            if (!jsonRes.success) {
                return {
                    error: "bad credentials"
                }
            }
            return jsonRes
        } catch (error) {
            let errorCode = error.code;
            let errorMessage = error.message;

            console.log("login error", errorCode, errorMessage)
            return {
                error: error.message
            }
        }
    } catch (e) {
        return {
            error: e
        }
    }
}

export const loginWithToken = async (idToken) => {
    const headers = new Headers({
        "Content-Type": "application/json"
    })
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            idToken
        })
    };
    let response = await fetch(apiRoutes.loginWithToken, init)
    let jsonRes = await response.json();
    if (!jsonRes.success) {
        return {
            error: "bad credentials"
        }
    }
    return jsonRes
}

export const sendPasswordReset = async (email, redirectURL) => {
    try {
        let actionCodeSettings = {
            url: redirectURL,
            handleCodeInApp: false
        };
        await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
    } catch (error) {
        return {
            error: error.message
        }
    }
}

export const loginValidation = async () => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
        })
    };
    let response = await fetch(apiRoutes.loginValidation, init)
    let jsonRes = await response.json();
    if (!jsonRes.success) {
        return {
            error: "bad credentials"
        }
    }
    return jsonRes
}

export const getAdminHouses = async () => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let response = await fetch(apiRoutes.adminHouses, init)
    let json = await response.json();
    return json
}

export const getHouseConfig = async (houseId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let response = await fetch(apiRoutes.getHouseConfig(houseId), init)
    let json = await response.json();
    return json
}

export const getHouseReport = async (startDate, endDate, compareTrendKey, tags, getHouseReport) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let route = apiRoutes.houseReport(startDate, endDate, compareTrendKey, tags, timeZone, getHouseReport)
    return await cacheRequest(route, init)
}

export const tableOverTimeData = async (sessionId, contentId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.tableOverTimeData(sessionId, contentId)
    return await cacheRequest(route, init)
}

export const getVideoOverTimeData = async (videoId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.tableVideoOverTimeData(videoId)
    return await cacheRequest(route, init)
}


export const tableVideoData = async (videoId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.tableVideoData(videoId)
    return await cacheRequest(route, init)
}

export const exportSessions = async (startDate, endDate, tags, timeZone, tagFilteringMode, residentId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.exportSessions(startDate, endDate, tags, timeZone, tagFilteringMode, residentId)
    let response = await fetch(route, init)
    let blob = await response.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", `sessions_export_${startDate}_${endDate}.csv`);
    a.click();
}

export const exportTableVideos = async (startDate, endDate, timeZone, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.exportTableVideos(startDate, endDate, timeZone, tags, tagFilteringMode)
    let response = await fetch(route, init)
    let blob = await response.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", `videos_export_${startDate}_${endDate}.csv`);
    a.click();
}

export const exportVideoData = async (startDate, endDate, timeZone, video, fullFileNameFormat) => {
    const videoId = video.videoId
    const videoName = video.name
    const videoDate = video.videoDate
    const algoVersion = video.algoVersion;

    const headers = await getAuthHeader();

    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.exportVideoData(startDate, endDate, timeZone, videoId)
    let response = await fetch(route, init)
    let blob = await response.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    let filename = fullFileNameFormat ? `${videoName} data ${videoDate} V_${algoVersion}.csv` : `${videoName}.csv`
    a.setAttribute("download", filename);
    a.click();
}

export const getResidentReport = async (startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.residentReport(startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode)
    return await cacheRequest(route, init)
}

export const getVideosReport = async (startDate, endDate, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.videosReport(startDate, endDate, tags, tagFilteringMode)
    return await cacheRequest(route, init)
}

export const saveAnalyzedVideo = async ({ tracker, name, date, seconds, trackerPath }) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            name,
            date,
            tracker,
            seconds,
            trackerPath
        })
    };

    let response = await fetch(apiRoutes.saveAnalyzedVideo, init)
    return await response.json();
}

export const getResidentSessionReport = async (startDate, endDate, compareTrendKey, residentId, sessionId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.residentSessionReport(startDate, endDate, compareTrendKey, residentId, sessionId)
    return await cacheRequest(route, init)
}

export const getHouseResidentsReport = async (startDate, endDate, compareTrendKey, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.houseResidentsReport(startDate, endDate, compareTrendKey, tags, tagFilteringMode)
    return await cacheRequest(route, init)
}

export const getResidentId = async (houseId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.residentId(houseId)
    return await cacheRequest(route, init)
}

export const getVideoProcessedData = async (videoId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.videoProcessedData(videoId)
    return await cacheRequest(route, init)

}

export const getResidentSessionsReport = async (startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.residentSessionsReport(startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode)
    return await cacheRequest(route, init)
}

export const getVideosTableReport = async (startDate, endDate, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.videosTableReport(startDate, endDate, tags, tagFilteringMode)
    return await cacheRequest(route, init)
}

export const getSessionTracksReport = async (startDate, endDate, compareTrendKey, residentId, sessionId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.sessionTracksReport(startDate, endDate, compareTrendKey, residentId, sessionId)
    return await cacheRequest(route, init)
}

export const uploadVideo = async ({ name, date, option, videoFile, listenerPath }) => {
    const data = new FormData()

    data.append('name', name)
    if (date) {
        data.append('date', date)
    }
    data.append('option', option)
    data.append('file', videoFile)
    data.append("listenerPath", listenerPath)

    let headers = await getAuthHeader()
    let init = {
        method: 'POST',
        headers: headers,
        body: data
    };

    let response = await fetch(apiRoutes.uploadFile, init)
    return await response.json();
}

export const deleteVideo = async (id, fileOnly = false) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify({ id, fileOnly })
    };
    let response = await fetch(apiRoutes.deleteVideo, init)
    return await response.json();
}

export const editVideo = async (id, name) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
            name, id
        })
    };
    let response = await fetch(apiRoutes.updateVideo, init)
    return await response.json();
}

export const saveResidentComment = async (residentId, comment) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            residentId,
            comment
        })
    };

    let response = await fetch(apiRoutes.residentComment, init)
    return await response.json();
}

export const saveSessionComment = async (sessionId, comment) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            sessionId,
            comment
        })
    };
    let response = await fetch(apiRoutes.sessionComment, init)
    return await response.json();
}

export const getTags = async (context) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.dashboardTags(context)
    return await cacheRequest(route, init)
}

export const getAnnotations = async (annotationType, relationId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.getAnnotations(annotationType, relationId)
    return await cacheRequest(route, init)
}

export const editAnnotation = async ({ name, startDate, endDate, annotationType, relationId }) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
            name,
            startDate,
            endDate,
            annotationType,
            relationId
        })
    };
    let response = await fetch(apiRoutes.editAnnotation, init)
    return await response.json();
}

export const saveAnnotation = async ({ name, startDate, endDate, annotationType, relationId }) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            name,
            startDate,
            endDate,
            annotationType,
            relationId
        })
    };

    let response = await fetch(apiRoutes.addAnnotation, init)
    return await response.json();
}

export const deleteAnnotation = async (annotation) => {
    const { name, startDate, endDate, annotationType, relationId } = annotation
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify({ name, startDate, endDate, annotationType, relationId })
    };
    let response = await fetch(apiRoutes.deleteAnnotation, init)
    return await response.json();
}

export const saveTag = async (id, tag, context) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            id,
            tag
        })
    };

    let response = await fetch(apiRoutes[`add${context}Tag`], init)
    return await response.json();
}

export const removeTag = async (id, tag, context) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify({
            id,
            tag
        })
    };
    let response = await fetch(apiRoutes[`delete${context}Tag`], init)
    return await response.json();
}

export const houseLogin = async (houseKey) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            houseKey
        })
    };
    let response = await fetch(apiRoutes.houseLogin, init)
    return await response.json();
}

export const orgLogin = async (orgId) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            orgId
        })
    };
    let response = await fetch(apiRoutes.orgLogin, init)
    return await response.json();
}

export const houseLogout = async () => {
    let headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers
    };
    return fetch(apiRoutes.houseLogout, init).then((response) => response.json())
}

export const housePreLoginValidation = async (email, houseKey) => {
    try {
        let headers = await getAuthHeader()
        headers.append("Content-Type", "application/json")
        let init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                email,
                houseKey
            })
        };
        let response = await fetch(apiRoutes.loginValidation, init)
        let validationRes = await response.json();
        if (!validationRes || !validationRes.admin) {
            return false
        } else {
            return true
        }
    } catch (e) {
        return false
    }

}

export const getBillingSessions = async (startDate, endDate) => {}
