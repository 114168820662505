import {
    calcEmotionsAvg,
    isEmotionHappy,
    isSad,
    isAngry,
    isDisgusted,
    isSurprised,
    isFearful,
    isNeutral,
    isEmotional,
    convertEmotions,
    convertCheckupEmotions,
    calcValenceTracker,
    calcValence,
    calcEnergyTracker,
    calcEnergy,
    calcStress,
    calcWellbeing,
    calcInterest,
    calcEngagement,
} from "solo-common"
import {
    ENGAGEMENT_HIGH,
    ENGAGEMENT_MEDIUM,
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    STRESS_HIGH, STRESS_LOW,
    STRESS_MEDIUM
} from "../../api/remoteConfig";
import {LEVELS} from "../../constants";
import colors from "../../styles/colors";


export const calcInterestLevel = (interest) => {
    let level
    if (interest > INTEREST_HIGH) {
        level = LEVELS.LIKE
    } else if (interest < INTEREST_HIGH && interest > INTEREST_MEDIUM) {
        level = LEVELS.NEUTRAL
    } else if (interest < INTEREST_MEDIUM) {
        level = LEVELS.DISLIKE
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return { value: level, color }
}

export const calcEngagementLevel = (engagement) => {
    let level
    if (engagement > ENGAGEMENT_HIGH) {
        level = LEVELS.HIGH_ENGAGEMENT
    } else if (engagement < ENGAGEMENT_HIGH && engagement > ENGAGEMENT_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (engagement < ENGAGEMENT_MEDIUM) {
        level = LEVELS.LOW_ENGAGEMENT
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return { value: level, color }
}

export const normalizeValue = (value) => {
    let res
    if (value < 0) {
        res = 0
    } else if (value > 1) {
        res = 1
    } else {
        res = value
    }
    return Math.round(res * 100)
}

export const calcWellbeingLevel = (wellbeing) => {
    let color, value = Math.round(normalizeValue(wellbeing))
    if (wellbeing >= 0.7) {
        color = colors.green
    } else if (wellbeing < 0.7 && wellbeing > 0.3) {
        color = colors.yellowish
    } else {
        color = colors.tomato
    }
    return { value, color }
}

export const getLevelColor = (level) => {
    switch (level) {
        case LEVELS.HIGH_STRESS:
        case LEVELS.DISLIKE:
        case LEVELS.LOW_ENGAGEMENT:
            return colors.tomato
        case LEVELS.MEDIUM:
        case LEVELS.NEUTRAL:
            return colors.yellowish
        case LEVELS.LOW_STRESS:
        case LEVELS.LIKE:
        case LEVELS.HIGH_ENGAGEMENT:
            return colors.green
        default:
            return colors.warm_grey
    }
}

export const calcStressLevel = (stress) => {
    let level
    if (stress > STRESS_HIGH) {
        level = LEVELS.HIGH_STRESS
    } else if (stress < STRESS_HIGH && stress > STRESS_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (stress < STRESS_MEDIUM && stress > STRESS_LOW) {
        level = LEVELS.LOW_STRESS
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return { value: level, color }
}

export {
    calcEmotionsAvg,
    isEmotionHappy,
    isSad,
    isAngry,
    isDisgusted,
    isSurprised,
    isFearful,
    isNeutral,
    isEmotional,
    convertEmotions,
    convertCheckupEmotions,
    calcValenceTracker,
    calcValence,
    calcEnergyTracker,
    calcEnergy,
    calcStress,
    calcWellbeing,
    calcInterest,
    calcEngagement
}
