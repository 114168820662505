import { createAsyncThunk } from '@reduxjs/toolkit';
import { getVideosReport, getVideosTableReport, uploadVideo } from "../../api"
import _, { findIndex, get } from "lodash";
import { SCREENS, STATUS } from "../../constants";
import { setMoodEnergyChartData, setStressMeterData, setWellbeingMeterData,  setPieChartData, setModulesAvgs, setTableInsights  } from "../top"

import { initEmotionDetection } from "../../utils/bioFeedback/faceApi"

import { getResidentSessionsReport, getResidentReport } from "../../api"
import { parseSessionsTableData, parseSessionsTopData } from "../../parsers"
import { parseVideosTopData, parseVideosTableData } from "../../parsers";
import { saveAnalyzedVideo } from "../../api/solo";
import firebase, {refreshToken} from "../../api/firebase"
import {toast} from "react-toastify";
import {setLimitReached} from "../aiUnitsLimit";

export const getVideosTopData = createAsyncThunk('videos/getTopData', async (data) => {
    const { startDate, endDate, tags, tagFilteringMode } = data
    const res = await getVideosReport(startDate, endDate, tags, tagFilteringMode)
    const parsedData = parseVideosTopData(res.data)
    return {...parsedData,startDate, endDate}
})

export const getVideosTableData = createAsyncThunk('videos/getTableData', async (data, { dispatch, getState }) => {
    const state = getState()
    const { currentHouse } = state.house

    let houseType = _.get(currentHouse, "type", null)
    let activeHouse = _.get(currentHouse, "active", null)

    console.log("getVideosTableData", data, houseType, activeHouse)

    if (houseType) {
        await initEmotionDetection()
    }

    const { startDate, endDate, tags, tagFilteringMode } = data
    const res = await getVideosTableReport(startDate, endDate, tags, tagFilteringMode)
    const videos = parseVideosTableData(res.data)

    if (_.get(res, "data", null)) {
        console.log("yo, videos", videos)
        console.log("setMoodEnergyChartData")
        dispatch(setMoodEnergyChartData({ data: videos, context: SCREENS.VIDEO }))
        console.log("setStressMeterData")
        dispatch(setPieChartData({ data: videos, context: SCREENS.VIDEO }))
        console.log("setWellbeingMeterData")
        dispatch(setModulesAvgs({ data: videos, context: SCREENS.VIDEO }))
        console.log("setTableInsights")
        dispatch(setTableInsights({ data: videos, context: SCREENS.VIDEO }))
    }

    //return videos
    return { currentHouseType: houseType, videos, activeHouse }
})

export const saveVideoData = createAsyncThunk('videos/saveData', async (data, { dispatch, getState }) => {
    const { name, date, tracker, seconds, videoData, tempId, houseId } = data

    const state = getState()
    const { tableData } = state.table

    let res
    let i = findIndex(tableData, (value) => {
        return value.tempId === tempId
    });

    if (videoData) {
        res = videoData
        console.log("videoData", videoData)
    } else {

        let payload = null
        try {
            let jsonString = JSON.stringify(tracker);
            const size = new TextEncoder().encode(jsonString).length
            const kiloBytes = size / 1024;
            const megaBytes = kiloBytes / 1024;
            if(megaBytes > 10){
                //upload tracker json to firebase storage bucket

                let temp = Date.now()
                let storagePath = `${name}_${date}_${seconds}_${houseId}.json`;
                const storageRef = firebase.storage().ref(storagePath)
                const task =  await storageRef.putString(btoa(jsonString), 'base64')
                payload = { name, date, seconds, trackerPath: storagePath }
                res = await saveAnalyzedVideo(payload)
           }
            else {
                payload = { name, date, seconds, tracker }
                res = await saveAnalyzedVideo(payload)
                console.log("res", res)
            }

            if(res && res.error){
                if(res.error === "You are not authorized to make this request"){
                    await refreshToken()
                    res = await saveAnalyzedVideo(payload)
                }

                if(res.error === "sdk app init failed"){
                    dispatch(setLimitReached(true))
                }

            }

            if (!res) {
                res = { engagement: 0, interest: 0, valence: 0, wellbeing: 0, stress: 0 }
            }
        } catch (e) {
            console.log("error in saveAnalyzedVideo", e)
        }
    }

    const parsedData = (parseVideosTableData([res]))[0]
    console.log("parsedData", parsedData)
    return { index: i, res: parsedData }
})

export const processVideo = createAsyncThunk('videos/processVideo', async (data, { dispatch, getState }) => {
    const timestamp = Date.now()
    const { houseId, videoData, tempId } = data

    let path = `videoProcessing/${houseId}/${timestamp}`;

    try {
        await firebase.database().ref(path).set({
            videoUploadStatus: STATUS.PENDING
        });
    } catch (e) {
        console.log("error updating videoUploadStatus", e)
    }

    const status = firebase.database().ref(`${path}`);
    status.on('value', async (snapshot) => {
        const data = snapshot.val();
        if (get(data, "videoUploadStatus", "") === STATUS.READY) {
            const videoData = get(data, "video", {})
            dispatch(setVideoReady(videoData.name))
            toast.success(`Video ${videoData.name} saved`)
            dispatch(saveVideoData({ videoData, tempId }))
            return firebase.database().ref(path).remove()
        }
    });

    videoData.listenerPath = path;
    await uploadVideo(videoData)
    return
})

export const setVideoReady = createAsyncThunk('videos/readyNotification', async (data, { dispatch, getState }) => {
    let videoName = data
    return videoName
})
