import { createSlice } from '@reduxjs/toolkit';
import _ from "lodash";
import {SCREENS, TABLE_COLUMNS} from "../../constants";
import {
    extractEnergyMoodChartDataFromTable,
    normalizedData,
    extractPieChartDataFromTable,
    calcModulesAVG,
    normalizePoints, normalizeDataPoints
} from "./utils";
import { getUsersTopData } from "../users/api"
import { getSessionsTopData } from "../sessions/api"
import { getContentTopData } from "../content/api"
import { getVideosTopData } from "../videos/api"
import {normalizedPercentage} from "../../views/Dashboard/TableData/utils";
import moment from "moment";
import {formatByHour, formatByDay} from "../../utils";
import {getString} from "../../utils/translations";
import {filterInsufficientData, handleInsufficientData} from "../table/utils";

export const emptyTopData = {
    "seconds": 0,
    "happiness": 0,
    "calmness": 0,
    "emotional": 0,
    "energy": 0,
    "valence": 0,
    "sessions": 0,
    "activeUsers": 0,
    overTimeEmotions: {
        happy: [],
        calm: [],
        sad: [],
        angry: [],
        mood: [],
        energy: [],
        wellbeing: [],
        interest: [],
        engagement: []
    },
    scores: null
}

const CARD_TYPE = {
    SESSIONS: "sessions",
    VIDEOS: "videos"
}

const extractInsightsFromOverTime = (overTime, labelFormatter) => {
    const insights = {}
    for (let key in overTime) {
        const data = overTime[key]
        let max = _.cloneDeep(_.maxBy(data, "value"))
        let min = _.cloneDeep(_.minBy(data, "value"))

        if(max && min) {
            if(labelFormatter){
                max.label = labelFormatter(max)
                min.label = labelFormatter(min)
            }

            insights[key] = {max, min}
        }

    }
    return insights
}

const extractInsightsFromTable = (tableData, insights = {}, moodKey) => {
    //console.log("extractInsightsFromTable", tableData)
    const addLabel = (item) => {
        if(!item){
            return null
        }
        return {
            ...item,
            label: item.name
        }
    }

    let tmpInsights = {
        ...insights,
        user: {
            stress: {
                min: addLabel(_.minBy(tableData, "stress")),
                max: addLabel(_.maxBy(tableData, "stress")),
            },
            wellbeing: {
                min: addLabel(_.minBy(tableData, "wellbeing")),
                max: addLabel(_.maxBy(tableData, "wellbeing")),
            },
            mood: {
                min: addLabel(_.minBy(tableData, moodKey)),
                max: addLabel(_.maxBy(tableData, moodKey)),
            },
            energy: {
                min: addLabel(_.minBy(tableData, "energy")),
                max: addLabel(_.maxBy(tableData, "energy")),
            }
        }
    }

    //console.log("insights", tmpInsights)
    return tmpInsights
}

const extractInsightsFromTopData = (topData, insights = {}) => {

    const {overTimeEmotionsByHour, overTimeEmotionsByDay, overTimeEmotions, startDate, endDate} = topData
    let tmpInsights = {...insights}
    tmpInsights.hour = extractInsightsFromOverTime(overTimeEmotionsByHour, (item) => {return formatByHour(item.date)})
    tmpInsights.day = extractInsightsFromOverTime(overTimeEmotionsByDay, (item) => {return formatByDay(item.date)})

    let duration = moment.duration(moment(startDate).diff(moment(endDate)))
    console.log("duration", duration.asMonths())
    if(Math.abs(duration.asMonths()) >= 3) {
        tmpInsights.month = extractInsightsFromOverTime(overTimeEmotions, (item) => {
            let date = _.get(item, "date" )
            if(!date){
                return ""
            }
            return getString(moment(date).format("MMMM"))
        })
    }else {
        tmpInsights.month = {
            stress: {
                min: null,
                max: null,
            },
            wellbeing: {
                min: null,
                max: null,
            },
            mood: {
                min: null,
                max: null,
            },
            energy: {
                min: null,
                max: null,
            }
        }
    }

   // console.log("extractInsightsFromTopData", tmpInsights)
    return tmpInsights
}

export const topSlice = createSlice({
    name: 'top',
    initialState: {
        loadingTopData: false,
        topData: emptyTopData,
        moodEnergyChartData: [],
        wellbeingMeterData: [],
        moodMeterData: [],
        energyMeterData: [],
        stressMeterData: [],
        wellbeingAvg: 0,
        moodAvg: 0,
        energyAvg: 0,
        stressAvg: 0,
        wellbeingAvgTrend: null,
        moodAvgTrend: null,
        energyAvgTrend: null,
        stressAvgTrend: null,
        wellbeingInsights: [],
        moodInsights: [],
        energyInsights: [],
        stressInsights: [],
        activeUsers: 0,
        showCostSaving: true,
        showSamples: false,
        cardType: CARD_TYPE.SESSIONS,
        perMinute: "session_minutes",
        overTimeData: emptyTopData.overTimeEmotions,
        lastWeekOT: emptyTopData.overTimeEmotions,
        lastMonthOT: emptyTopData.overTimeEmotions,
        breakOvertime: false,
        scores: null,
        insights: null
    },
    reducers: {
        setTopData: (state, action) => {
            const data = normalizedData(action.payload)
            state.topData = data || emptyTopData
        },
        setMoodEnergyChartData: (state, action) => {
            const { data, context } = action.payload

            const moodEnergyChartData = extractEnergyMoodChartDataFromTable(data, context)
           // console.log("moodEnergyChartData", moodEnergyChartData)
            state.moodEnergyChartData = normalizeDataPoints(moodEnergyChartData)
          //  console.log("state.moodEnergyChartData", state.moodEnergyChartData)
        },
        setStressMeterData: (state, action) => {
            const data = action.payload
            const stressMeterData = extractPieChartDataFromTable(data, TABLE_COLUMNS.STRESS)
            state.stressMeterData = stressMeterData
        },
        setWellbeingMeterData: (state, action) => {
            const data = action.payload
            const wellbeingMeterData = extractPieChartDataFromTable(data, TABLE_COLUMNS.WELLBEING)
            state.wellbeingMeterData = wellbeingMeterData
        },
        setPieChartData: (state, action) => {
            const {data, context} = action.payload
            let moodKey = context === SCREENS.VIDEO ? "valence" : "valence_ps"
            state.wellbeingMeterData = extractPieChartDataFromTable(data, TABLE_COLUMNS.WELLBEING)
            state.stressMeterData = extractPieChartDataFromTable(data, TABLE_COLUMNS.STRESS)
            state.moodMeterData = extractPieChartDataFromTable(data, TABLE_COLUMNS.MOOD, moodKey)
            state.energyMeterData = extractPieChartDataFromTable(data, "energy")
        },
        setModulesAvgs: (state, action) => {
            const {data, context} = action.payload
         //   console.log("setModulesAvgs", data)
            let moodKey = context === SCREENS.VIDEO ? "valence" : "valence_ps"
            let {wellbeing, stress, mood, energy, trend} = calcModulesAVG(data, moodKey)
            //console.log("setModulesAvgs", {wellbeing, stress, mood, energy, trend})
            state.wellbeingAvg = normalizedPercentage(wellbeing)
            state.moodAvg = normalizedPercentage(mood)
            state.energyAvg = normalizedPercentage(energy)
            state.stressAvg = normalizedPercentage(stress)
            state.wellbeingAvgTrend = _.get(trend, "wellbeing", null)
            state.moodAvgTrend = _.get(trend, "valence_ps", null)
            state.energyAvgTrend = _.get(trend, "energy", null)
            state.stressAvgTrend = _.get(trend, "stress", null)
        },
        setOverTimeData: (state, action) => {
            state.overTimeData = action.payload
        },
        setTableInsights: (state, action) => {
            const {data, context} = action.payload
            const filtered = filterInsufficientData(data)
            let moodKey = context === SCREENS.VIDEO ? "valence" : "valence_ps"
            state.insights = extractInsightsFromTable(filtered, state.insights, moodKey)
        }
    },
    extraReducers: {
        [getUsersTopData.pending]: (state, action) => {
            state.loadingTopData = true
        },
        [getUsersTopData.fulfilled]: (state, { payload }) => {
           // console.log("getUsersTopData.fulfilled", payload)
            state.loadingTopData = false
            state.activeUsers = payload.activeUsers || 0
            state.cardType = CARD_TYPE.SESSIONS
            state.perMinute = "session_minutes"
            state.showCostSaving = true
            state.showSamples = false
            state.overTimeData = payload.overTimeEmotions
            state.scores = payload.scores;
            state.topData = payload || emptyTopData
            state.breakOvertime = false
            state.lastWeekOT = payload.lastWeekOT
            state.lastMonthOT = payload.lastMonthsOT
            state.insights = extractInsightsFromTopData(payload, state.insights)
        },
        [getUsersTopData.rejected]: (state, action) => {
            console.error("error getting users top data", action)
            state.loadingTopData = false
        },
        [getSessionsTopData.pending]: (state, { payload }) => {
            state.loadingTopData = true
        },
        [getSessionsTopData.fulfilled]: (state, { payload }) => {
            state.loadingTopData = false
            state.activeUsers = 1
            state.cardType = CARD_TYPE.SESSIONS
            state.perMinute = "session_minutes"
            state.showCostSaving = true
            state.showSamples = false
            state.overTimeData = payload.overTimeEmotions
            state.scores = payload.scores;
            state.topData = payload || emptyTopData
            state.breakOvertime = true
            state.lastWeekOT = payload.lastWeekOT
            state.lastMonthOT = payload.lastMonthsOT
            state.insights = extractInsightsFromTopData(payload, state.insights)
        },
        [getSessionsTopData.rejected]: (state, { payload }) => {
            console.error("error getting sessions top data", payload)
            state.loadingTopData = false
        },
        [getContentTopData.pending]: (state, { payload }) => {
            state.loadingTopData = true
        },
        [getContentTopData.fulfilled]: (state, { payload }) => {
            state.loadingTopData = false
            state.activeUsers = 1
            state.cardType = CARD_TYPE.SESSIONS
            state.perMinute = "session_minutes"
            state.showCostSaving = true
            state.showSamples = false
            state.overTimeData = payload.overTimeEmotions
            state.scores = payload.scores;
            state.topData = payload || emptyTopData
            state.breakOvertime = false
        },
        [getContentTopData.rejected]: (state, { payload }) => {
            console.error("error getting content top data")
            state.loadingTopData = false
        },
        [getVideosTopData.pending]: (state, { payload }) => {
            state.loadingTopData = true
        },
        [getVideosTopData.fulfilled]: (state, { payload }) => {
            state.loadingTopData = false
            state.activeUsers = null
            state.cardType = CARD_TYPE.VIDEOS
            state.perMinute = "video_minutes"
            state.showCostSaving = false
            state.showSamples = false
            state.overTimeData = payload.overTimeEmotions
            state.scores = payload.scores;
            state.topData = payload || emptyTopData
            state.breakOvertime = false
            state.lastWeekOT = payload.lastWeekOT
            state.lastMonthOT = payload.lastMonthsOT
            state.insights = extractInsightsFromTopData(payload, state.insights)
        },
        [getVideosTopData.rejected]: (state, { payload }) => {
            console.error("error getting videos top data")
            state.loadingTopData = false
        }
    }
});

const { actions, reducer } = topSlice;

export const { setTopData, setMoodEnergyChartData, setOverTimeData,
    setStressMeterData, setWellbeingMeterData, setPieChartData, setModulesAvgs, setTableInsights } = actions;
export default reducer;
