import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PopUp from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

import styled from "styled-components";
import colors from "../styles/colors";
import Lottie from 'react-lottie';

import _, { get, findIndex } from "lodash";
import { getAsset } from "../utils"
import { getString } from "../utils/translations"
import { Fragment } from 'react';
import { STATUS } from '../constants';

import Video from "../components/Video"
import OverTimeChart from './Charts/OverTime';
import Popup from './Popup';
import { setVideoTracker } from "../redux/videos"
import { pushToTableData } from "../redux/table"

import { saveVideoData } from "../redux/videos/api"

import { connect } from "react-redux";

class VideoAnalyzePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelAnalyzeProcessPopupOpen: false,
            analyzing: false,
            backgroundProcessing: false,

        }
    }

    handleCloseButton = () => {
        this.setState({ cancelAnalyzeProcessPopupOpen: true })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {savingVideo} = this.props

        if(!savingVideo && prevProps.savingVideo){
            this.props.closeHandler()
        }

    }

    handleSaveButton = () => {
        const { setVideoTracker, tracker, title, videoDuration, date, pushToTableData, saveVideoData, houseId } = this.props

        const tempId = Date.now()
        const name = title
        const seconds = tracker.length ? get(tracker[tracker.length - 1], "currentTime", 0) : 0
        const videoDate = date
        const status = STATUS.PROCESSING

        pushToTableData({ tempId, name, seconds, videoDate, status }) //pin (?)
        saveVideoData({ tracker, name, tempId, date: videoDate, seconds, houseId })

        setVideoTracker([])
    }

    renderHeader = () => {
        const { title, videoAnalyzeForLiveStream, savingVideo } = this.props
        const {analyzing, backgroundProcessing} = this.state
        return (<Header>
            <Button src={getAsset("icons/xIcon.svg")} onClick={this.handleCloseButton} width={25} />
            <Title>{title}</Title>
            {((analyzing || backgroundProcessing) && !videoAnalyzeForLiveStream) || savingVideo ? (
                    <Button src={getAsset("loader.svg")} onClick={()=>{}} width={30}/>) :
                <Button src={getAsset("icons/vIcon.svg")} onClick={this.handleSaveButton} width={30}/>}
        </Header>)
    }

    renderBody = (height) => {
        return (<Body>
            <Video
                videoAnalyzeForLiveStream={this.props.videoAnalyzeForLiveStream}
                videoFile={this.props.videoFile}
                faceApiModel={this.props.faceApiModel}
                overTimeChart={true} height={height}
                setAnalyzing={(analyzing)=>{
                    this.setState({analyzing})
                }}
                setBackgroundProcessing={(processing)=>{this.setState({backgroundProcessing: processing})}}
                backgroundProcessing={this.state.backgroundProcessing}
                detectionInterval={this.props.videoAnalyzeForLiveStream ? 200 : 500}
            />
        </Body>)
    }

    handleDeleteAnalyzedData = () => {
        const { setVideoTracker } = this.props
        this.setState({ cancelAnalyzeProcessPopupOpen: false })
        setVideoTracker([])
        this.props.closeHandler()
    }


    handleKeepAnalyzedData = () => {
        this.setState({ cancelAnalyzeProcessPopupOpen: false })
    }

    render() {

        const height = window.screen.height * 77 / 100
        const width = height * 1.15

        const { cancelAnalyzeProcessPopupOpen, analyzing, backgroundProcessing } = this.state
        const { isOpen, closeHandler, closeOnDocumentClick, title, closeCancelAnalyzeProcessPopupOpen, savingVideo } = this.props

        return (
            <PopUp
                position="center"
                closeOnDocumentClick={closeOnDocumentClick}
                open={isOpen}
                onClose={closeHandler}
                contentStyle={{
                    width,
                    height,
                    padding: 0,
                    borderRadius: 9,
                    boxShadow: "0px 1.13559px 14.1949px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {this.renderHeader()}

                {this.renderBody(height - headerHeight)}

                {cancelAnalyzeProcessPopupOpen && <Popup
                    closeOnDocumentClick={false}
                    isOpen={cancelAnalyzeProcessPopupOpen}
                    title={getString("delete_analyze_video_popup_title")}
                    description={getString("delete_analyze_video_popup_message")}
                    footerButtons={[{ label: getString("delete"), onPress: this.handleDeleteAnalyzedData, background: colors.lipstick },
                    { label: getString("keep_data"), onPress: this.handleKeepAnalyzedData, background: colors.green }]}
                />}
            </PopUp>
        );
    }
}

const mapStateToProps = ({ video }) => ({
    tracker: video.tracker,
    savingVideo: video.savingVideo
});

const mapDispatchToProps = {
    setVideoTracker,
    pushToTableData,
    saveVideoData
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoAnalyzePopup);

const Title = styled.div`
            font-family: Open Sans;
            font-weight: 900;
            font-size: 27px;
            color: #536EEC;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.3px;
            `

let headerHeight = 40;
const Header = styled.div `
            height: ${headerHeight}px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 7px;
            padding-right: 7px;
            `

const Body = styled.div`
            flex: 1;
            display: flex;
            flex-direction: column;
            `

const Button = styled.img`
    cursor: pointer;
`
