import * as faceapi from 'face-api.js';

let MODELS_DIR = process.env.PUBLIC_URL + '/models';

export const initEmotionDetection = async () => {
    await faceapi.nets.ssdMobilenetv1.loadFromUri(MODELS_DIR)
    await faceapi.nets.tinyFaceDetector.loadFromUri(MODELS_DIR)
    await faceapi.nets.faceExpressionNet.loadFromUri(MODELS_DIR)
    await faceapi.nets.faceLandmark68Net.loadFromUri(MODELS_DIR)

    await faceapi.loadFaceRecognitionModel(MODELS_DIR)

    console.log("Models loaded", faceapi.nets)
    return true
}

export const detectEmotions = async (input, options) => {
    const detectionsWithExpressions = await faceapi.detectAllFaces(input, options).withFaceExpressions()
    return detectionsWithExpressions;
}
