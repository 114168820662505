import React, { Component } from 'react';
import styled from 'styled-components'
import ProgressBar from "./Charts/ProgressBar";
import colors from '../styles/colors';
import _ from 'lodash'
import { Fragment } from 'react';
import { getString } from "../utils/translations";

class DetectionSquare extends Component {

    renderExtension = () => {

        const { stressLevel, interestLevel, engagementLevel, wellbeingLevel } = this.props

        return (<Fragment>
            <MeterContainer>
                <Title>
                    {getString("stress")}
                </Title>
                <Value color={stressLevel && stressLevel.color}>
                    {getString(stressLevel && stressLevel.value)}
                </Value>
            </MeterContainer>

            <MeterContainer>
                <Title>
                    {getString("interest")}
                </Title>
                <Value color={interestLevel && interestLevel.color} >
                    {getString(interestLevel && interestLevel.value)}
                </Value>
            </MeterContainer>

            <MeterContainer>
                <Title>
                    {getString("engagement")}
                </Title>
                <Value color={engagementLevel && engagementLevel.color}>
                    {getString(engagementLevel && engagementLevel.value)}
                </Value>
            </MeterContainer>

            <MeterContainer>
                <Title>
                    {getString("wellbeing")}
                </Title>
                <Value color={wellbeingLevel && wellbeingLevel.color}>
                    {getString(wellbeingLevel && wellbeingLevel.value)}
                </Value>
            </MeterContainer>
        </Fragment>)
    }

    render() {

        let marginLeft = null

        const { top, left, width, height, label, percentage, progressColor, emphasize, extendedDetectionSquare } = this.props
        let progressWidth = width

        if (width < 150) {
            progressWidth = width + 45
        }

        return (
            <div style={{ position: 'absolute', top, left, zIndex: 5 }}>
                <div style={{ width, height }}>
                    <Square height={height} color={emphasize ? colors.blue : colors.white} />
                </div >
                {extendedDetectionSquare &&
                    <div style={{ width: progressWidth, minWidth: width, position: 'absolute', marginLeft }}>
                        <ProgressBar
                            label={label}
                            percentage={percentage}
                            progressColor={progressColor}
                            backgroundColor={"white"}
                            width={progressWidth}
                            height={18}
                            showValue={true} />
                        {extendedDetectionSquare && this.renderExtension()}
                    </div>
                }
            </div >

        );
    }
}

const Square = styled.div`
    // width: 100%;
    height: ${props => props.height}px;
    border: 2px solid ${props => props.color};
    box-sizing: border-box;
`

const MeterContainer = styled.div`
    display: flex;
    background: white;
    height: 18px;
    position: relative;
`

const Title = styled.div`
    font-size: 9px;
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    top: 0;
    margin-left: 5px;
    font-weight: 700;
`

const Value = styled.div`
    font-size: 9px;
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 0;
    top: 0;
    margin-right: 5px;
    color: ${props => props.color};
`

export default DetectionSquare;