import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import { detectEmotions } from "../utils/bioFeedback/faceApi"
import _ from "lodash";
import {
    calcEnergyTracker,
    calcEngagement,
    calcInterest,
    calcStress,
    calcValenceTracker,
    calcWellbeing
} from "../utils/bioFeedback";

class VideoFaceDetector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            duration: 0,
        }

        this.videoElement = null;
        this.currentTime = 0;
    }

    videoReady = () => {
        console.log("videoReady")
        this.setState({ videoReady: true })
        const {videoStarted} = this.props;

        this.videoElement = document.querySelector("#yo video")
        this.videoElement.addEventListener("loadedmetadata", ()=>{
            console.log("loadedmetadata", {width: this.videoElement.videoWidth, height: this.videoElement.videoHeight})

        })

        if(videoStarted){
            videoStarted()
        }
    }

    videoDuration = (duration) => {
        const { interval } = this.props;
        console.log("duration", duration)
        this.setState({ duration: duration })
        this.player.seekTo(interval / 1000, "friction")
    }

    onSeek = async (seconds) => {
        const { duration } = this.state;
        const { interval, options, onProgress } = this.props;
        console.log("onSeek", seconds)
        this.currentTime = seconds;
        onProgress && (onProgress({currentTime: seconds, duration}))
        this.videoElement = document.querySelector("#yo video")
        await this.detectEmotions()
        if (this.currentTime < duration && this.player) {
            this.player.seekTo(this.currentTime + interval / 1000, "friction")
        } else {
            this.props.onFinishDetection && this.props.onFinishDetection()
        }
    }

    ref = player => {
        this.player = player
        console.log("ref", this.player)
        console.log("this.videoElement", this.videoElement)
    }

    detectEmotions = async () => {

        const { setVideoTracker, tracker, options } = this.props

        try {
         //   console.time("detectEmotions")
            let result = await detectEmotions(this.videoElement, options)
        //    console.timeEnd("detectEmotions")
        //    console.log("detection", result)
            let currentTime = this.currentTime
            if (result && result.length) {

                const res = []

                let i = 0
                for (const r of result) {

                    let id = i++

                    const { angry, disgusted, fearful, happy, neutral, sad, surprised } = r.expressions
                    let avg = { happiness: happy, neutral, angry, disgusted, surprised, sad, fearful }

                    const results = this.getResults(avg)
                    const { valence, energy, stress, wellbeing, interest, engagement } = results

                    const detectionResult = {
                        id,
                        expressions: { angry, disgusted, fearful, happy, neutral, sad, surprised },
                        detection: {
                            ...r.detection,
                            _imageDims: { ...r.detection._imageDims },
                            _box: { ...r.detection._box }
                        },
                        energy,
                        engagement,
                        interest,
                        stress,
                        valence,
                        wellbeing,
                    }

                    res.push(detectionResult)

                }

                let updatedTracker = _.cloneDeep(tracker)
                let emotions = this.calcResultAvg(res)

                updatedTracker.push({
                    currentTime,
                    result: res,
                    emotions
                })

                setVideoTracker(updatedTracker)
            }else {
                let updatedTracker = _.cloneDeep(tracker)
                updatedTracker.push({
                    currentTime,
                    result: [],
                    emotions: null
                })

                setVideoTracker(updatedTracker)
            }

        } catch (e) {
            console.log("error", e)
        }
    }

    calcResultAvg = (result = []) => {
        const arrayAvg = (arr) => {
            if (!arr.length) {
                return 0;
            }
            return arr.reduce((p, c) => p + c, 0) / arr.length
        }

        let avgArr = {
            neutral: [],
            happiness: [],
            sad: [],
            angry: [],
            fearful: [],
            disgusted: [],
            surprised: [],
            energy: [],
            engagement: [],
            interest: [],
            stress: [],
            valence: [],
            wellbeing: [],
        }

        result.map((item) => {
            avgArr.neutral.push(item.expressions.neutral)
            avgArr.happiness.push(item.expressions.happy)
            avgArr.sad.push(item.expressions.sad)
            avgArr.angry.push(item.expressions.angry)
            avgArr.fearful.push(item.expressions.fearful)
            avgArr.disgusted.push(item.expressions.disgusted)
            avgArr.surprised.push(item.expressions.surprised)
            avgArr.energy.push(item.energy)
            avgArr.engagement.push(item.engagement)
            avgArr.interest.push(item.interest)
            avgArr.stress.push(item.stress)
            avgArr.valence.push(item.valence)
            avgArr.wellbeing.push(item.wellbeing)
        })

        let avg = {
            neutral: arrayAvg(avgArr.neutral),
            happy: arrayAvg(avgArr.happiness),
            sad: arrayAvg(avgArr.sad),
            angry: arrayAvg(avgArr.angry),
            fearful: arrayAvg(avgArr.fearful),
            disgusted: arrayAvg(avgArr.disgusted),
            surprised: arrayAvg(avgArr.surprised),
            energy: arrayAvg(avgArr.energy),
            engagement: arrayAvg(avgArr.engagement),
            interest: arrayAvg(avgArr.interest),
            stress: arrayAvg(avgArr.stress),
            valence: arrayAvg(avgArr.valence),
            wellbeing: arrayAvg(avgArr.wellbeing),
        }

        return avg;
    }

    getResults = (avg) => {
        const valence = calcValenceTracker(avg)
        const energy = calcEnergyTracker(avg)
        const stress = calcStress(avg)
        const wellbeing = calcWellbeing(avg)
        const interest = calcInterest(avg)
        const engagement = calcEngagement(avg)

        return {
            valence,
            energy,
            stress,
            wellbeing,
            interest,
            engagement
        }
    }

    render() {
        return (
            <div style={{
                visibility: "hidden",
                position: 'absolute'
            }}>
                <ReactPlayer
                    id={"yo"}
                    ref={this.ref}
                    onReady={this.videoReady}
                    onDuration={this.videoDuration}
                    onSeek={this.onSeek}
                    controls={true} url={this.props.src} />
            </div>
        );
    }
}

VideoFaceDetector.propTypes = {
    interval: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    onFinishDetection: PropTypes.func,
    options: PropTypes.any,
    tracker: PropTypes.array.isRequired,
    setVideoTracker: PropTypes.func,
    onProgress: PropTypes.func,

};

export default VideoFaceDetector;
