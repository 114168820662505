import React, {Component, useRef, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTable, useSortBy, useGlobalFilter, useAsyncDebounce, useExpanded} from 'react-table'
import memoize from 'memoize-one';
import _, {get, findIndex} from "lodash";
import styled from "styled-components";
import {getAsset} from "../../../utils";
import colors from "../../../styles/colors";
import fonts from "../../../styles/fonts";
import moment from "moment"
import {getNormalizedEmotionsMinutes} from "../../../utils/charts";
import {getString, translations} from "../../../utils/translations";
import {useSelector} from 'react-redux';
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Video from "../../../components/Video";
import VideoAnalyzePopup from "../../../components/VideoAnalyzePopup";
import EditableField from '../../../components/EditableField';
import VideoUploadPopup from "../VideoUploadPopup";
import {OverTimeButton} from "../../../components/solo-styled-components";


import {
    RESIDENT_TYPE, OVER_TIME_BUTTONS, COLUMN_VALUE_OPTION, STATUS,
    OVER_TIME_EMOTIONS_BUTTONS,
    HOUSE_TYPES, TABLE_COLUMNS, REQUEST_DATE_FORMAT, SCREENS
} from "../../../constants";

import {calcSufficientData} from "../../../redux/table/utils"
import {connect} from "react-redux";
import OverTimeChart from '../../../components/Charts/OverTime';
import features from "../../../features";
import {getTableOverTimeData, getTableVideoData} from '../../../redux/table/api';
import {
    setSelectedOvertimeButtons, setTableOvertime, removeFromTable, editTableRow,
    setShowInsufficientData, pushToTableData
} from "../../../redux/table"
import {initVideoReady, setVideoTracker} from "../../../redux/videos"
import {deleteVideo, editVideo, exportSessionOvertime, exportVideoData} from '../../../api';
import {processVideo} from "../../../redux/videos/api"
import Popup from "../../../components/Popup";


import {setInsufficientDataMode} from "../../../api/AsyncStorage"
import {
    formatMinutes,
    normalizedPercentage,
    getEmotionKey,
    isCheckup,
    getColor,
    format,
    formatSecondsToTimeString
} from "./utils"

import Wellbeing from './Wellbeing';
import Stress from './Stress';
import Insufficient from './Insufficient';
import Processing from './Processing'
import Emotion from './Emotion';
import Minutes from './Minutes';
import Icon from './Icon';
import Name from './Name';
import SecondCell from './SecondCell';
import Type from './Type';
import DateCell from './DateCell';
import {Fragment} from 'react';

import loader from '../../../lotties/loaderhouse.json'
import Lottie from 'react-lottie';

import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import VideoPreviewPopup from "../../../components/VideoPreviewPopup";

import {exportButtonStyle} from "../../../styles/layout";
import {ExportText} from "../../../styles/typography";

export const memoNormalizedEmotionsMinutes = memoize(getNormalizedEmotionsMinutes);

function GlobalFilter({
                          preGlobalFilteredRows,
                          globalFilter,
                          setGlobalFilter,
                          currPlaceholder
                      }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
    return (
        <div style={{display: "flex"}}>
            <Input
                type={"text"}
                value={value}
                onChange={(value) => {
                    setValue(value);
                    onChange(value);
                }}
                placeholder={getString(`${currPlaceholder}`)}
                placeholderIcon={"icons/search.svg"}
                style={{width: 200}}
            />
        </div>
    )
}

function DataToShowCheckBox({value, setValue}) {
    return <FormControlLabel style={{marginTop: 10, color: "#8798AD"}}
                             control={< div style={{
                                 marginRight: -7
                             }}>
                                 <Checkbox
                                     checked={value}
                                     color="primary"
                                     onChange={() => {
                                         setValue(!value);
                                     }}
                                 />
                             </div>}
                             label={getString("table_data_checkbox")}
    />;
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid'
    }
};


const TableData = ({
                       data,
                       onScreenChange,
                       headerTitle,
                       setTableOvertime,
                       exportInRow,
                       startDate,
                       endDate,
                       currentScreen,
                       currPlaceholder,
                       hasNext,
                       rowsIcon,
                       colData,
                       isShowSeconds,
                       subRowControls,
                       removeFromTable,
                       editTableRow,
                       pushToTableData,
                       isDesc,
                       sortBy,
                       contentType,
                       showLastSeen,
                       pinnedResidents,
                       updateTableData,
                       updateTags,
                       showInsufficientData,
                       tableOvertime,
                       selectedOvertimeButtons,
                       loadingRowOvertime,
                       currentHouse,
                       columnValue,
                       tableVideo,
                       useValencePS,
                       setSelectedOvertimeButtons,
                       getTableOverTimeData,
                       uploadButton,
                       videoInExpander,
                       getTableVideoData,
                       setShowInsufficientData,
                       processVideo,
                       initVideoReady,
                       videoReady,
                       setVideoTracker
                   }) => {

    const [tableRows, setTableRows] = useState([])

    const {currentLanguage} = useSelector(state => state.translations)
    const currLangObj = translations.supportedLanguages.find(l => l.key === currentLanguage)
    const lang = currLangObj ? currLangObj.fullName : null

    const [deleteVideoPopupOpen, setDeleteVideoPopupOpen] = useState(false)
    const [uploadVideoPopup, setUploadVideoPopup] = useState(false)
    const [videoStreamPopup, setVideoStreamPopup] = useState(false)
    const [currentSubRow, setCurrentSubRow] = useState({})

    const [videoAnalyzeForLiveStream, setVideoAnalyzeForLiveStream] = useState(false)

    const [videoAnalyzePopupOpen, setVideoAnalyzePopupOpen] = useState(false)
    const [videoFile, setVideoFile] = useState(null)
    const [videoDate, setVideoDate] = useState(null)
    const [videoDuration, setVideoDuration] = useState(null)
    const [faceApiModel, setFaceApiModel] = useState(null)
    const [videoAnalyzePopupTitle, setVideoAnalyzePopupTitle] = useState("")
    const [addTimeCol, setAddTimeCol] = useState(null)
    const [isOpenVideoPreview, setIsOpenVideoPreview] = useState(false)
    const [videoPreview, setVideoPreview] = useState(null)
    const [uploadingVideo, setUploadingVideo] = useState(false)

    const happyRef = useRef()
    const calmRef = useRef()
    const sadRef = useRef()
    const angryRef = useRef()

    const [refVisible, setRefVisible] = useState(false)

    useEffect(() => {
        if (!refVisible) {
            return
        }
        // detected rendering
    }, [refVisible])

    useEffect(() => {
        let addTimeCol = colData.find(item => item.header === "time")
        setAddTimeCol(addTimeCol)
    }, [colData])

    const pinUploadButton = (rows) => {

        let index = findIndex(rows, (item) => {
            return get(item, "original.id", "") === "btn-stream"
        });
        if (index !== -1) {
            rows.unshift(rows.splice(index, 1)[0]);
        }

        index = findIndex(rows, (item) => {
            return get(item, "original.id", "") === "btn"
        });
        if (index !== -1) {
            rows.unshift(rows.splice(index, 1)[0]);
        }

        setTableRows(rows)
    }

    const hourSort = (rowA, rowB, id, desc) => {

        const minutesOfDay = function (m) {
            return m.minutes() + m.hours() * 60;
        }

        if (id === "hour") {

            let a = minutesOfDay(moment(rowA.values[id]));
            let b = minutesOfDay(moment(rowB.values[id]));

            if (a > b) return 1;
            if (b > a) return -1;
            return 0;


        } else {
            if (rowA.values[id] > rowB.values[id]) return 1;
            if (rowB.values[id] > rowA.values[id]) return -1;
            return 0;
        }
    }

    const timeSort = (rowA, rowB, id, desc) => {
        const minutesOfDay = (time) => {
            let timeString = time.split(":")
            return parseInt(timeString[0]) * 60 + parseInt(timeString[1])
        }

        if (id === "time") {
            let a = minutesOfDay((rowA.values[id]));
            let b = minutesOfDay((rowB.values[id]));

            if (a > b) return 1;
            if (b > a) return -1;
            return 0;

        } else {
            if (rowA.values[id] > rowB.values[id]) return 1;
            if (rowB.values[id] > rowA.values[id]) return -1;
            return 0;
        }
        return 0;
    }

    const pinResidents = (rows) => {
        let indexes = (rows.map((row, i) => get(row, "original.type", "") === RESIDENT_TYPE.GROUP || get(row, "original.type", "") === RESIDENT_TYPE.CARE ? i : '').filter(String)).reverse()
        let pinnedResident

        function injectPinnedResident(rowsIndex) {
            if (rowsIndex !== -1) {
                const pinnedResidentArray = rows.splice(rowsIndex, 1)
                pinnedResident = pinnedResidentArray[0]
                const targetIndex = pinnedResident.original.pinIndex
                rows.splice(targetIndex, 0, pinnedResident);
            }
        }

        if (!indexes.length) {
            setTableRows(rows)
        } else {
            let groupIndex = findIndex(rows, (item) => {
                return get(item, "original.type", "") === RESIDENT_TYPE.GROUP
            });
            injectPinnedResident(groupIndex)

            let careIndex = findIndex(rows, (item) => {
                return get(item, "original.type", "") === RESIDENT_TYPE.CARE
            });
            injectPinnedResident(careIndex);
            setTableRows(rows)
        }
    }

    function openDeleteVideoDialog({original, id, fileOnly}) {
        setDeleteVideoPopupOpen(true);
        setCurrentSubRow({
            originalRow: original,
            rowId: id,
            fileOnly
        })
    }

    const columns = React.useMemo(
        () => [
            {
                id: colData[0].header,
                Header: getString(`${colData[0].header}`),
                accessor: (originalRow) => {
                    let val
                    if (colData[0].header !== "date" && (lang && get(originalRow, lang))) {
                        val = get(originalRow, lang)
                    } else {
                        val = get(originalRow, colData[0].value, "")
                    }
                    let alert = get(originalRow, "alerts.stressAlert", null) || get(originalRow, "alerts.sadAlert", null)
                    if (alert) {
                        return `${Date.now()} ${get(originalRow, "lastSeen", null)} ${get(originalRow, "comment", null)} ${val}`
                    }
                    return `${get(originalRow, "lastSeen", null)} ${get(originalRow, "comment", null)} ${val}`
                },
                Cell: ({row, rows}) => {
                    let pinned = null
                    let residentType = get(row, "original.type", "");
                    let rowId = get(row, "original.id", "");
                    if (pinnedResidents && residentType === RESIDENT_TYPE.GROUP
                        || residentType === RESIDENT_TYPE.CARE
                        || rowId === "btn"
                        || rowId === "btn-stream") {
                        pinned = true
                    }
                    let colValue
                    if (colData[0].header !== "date" && (lang && get(row.original, lang))) {
                        colValue = get(row.original, lang)
                    } else {
                        colValue = get(row.original, colData[0].value, "")
                    }

                    return (
                        <CellContainer contentPositioning={rowsIcon ? "start" : "center"}
                                       marginLeft={rowsIcon ? '10px' : null}>
                            <Icon pinned={pinned} row={row}/>
                            <div style={{maxWidth: '75%'}}>
                                {colData[0].header === "date" ? <DateCell value={moment(colValue).format("DD.MM.YYYY")}
                                                                          showAddTag={!features.showType}
                                                                          updateTags={updateTags} row={row.original}/>
                                    : <Name value={colValue} pinned={pinned} row={row.original} updateTags={updateTags}
                                            updateTableData={updateTableData} rows={rows}/>}
                            </div>
                        </CellContainer>
                    )
                }
            },
            ...((colData[1].header === "artist" && !features.showArtist) ? [] : [{
                id: colData[1].header,
                Header: () => (<div style={{
                    textAlign: colData[1].textAlign, marginRight: colData[1].headerMargin
                }}>{getString(`${colData[1].header}`)}</div>),
                accessor: (originalRow) => {
                    return get(originalRow, colData[1].value, 0)
                },
                Cell: ({row, value}) => {
                    return (
                        ["btn", "btn-stream"].includes(get(row, "original.id", "")) ? <div></div>
                            : <SecondCell row={row.original} value={value} data={colData[1]}/>)
                },
                sortType: hourSort

            }]),
            {
                id: "MINUTES",
                Header: getString("capital_minutes"),
                accessor: (originalRow, rowIndex) => {
                    const duration = get(originalRow, "seconds", 0)
                    return isShowSeconds ? formatMinutes(duration) : Math.round(duration / 60)
                },
                Cell: ({row, value}) => {
                    let checkupSession = isCheckup(row.original)
                    return (
                        ["btn", "btn-stream"].includes(get(row, "original.id", "")) || checkupSession ? <div></div>
                            : <Minutes value={value} item={row.original}/>
                    )
                }
            },
            {
                id: "ENGAGEMENT",
                Header: () => (<div ref={el => {
                    happyRef.current = el;
                    setRefVisible(!!el);
                }}>
                    {getString("engagement")}</div>),
                accessor: (originalRow, rowIndex) => {
                    return normalizedPercentage(get(originalRow, getEmotionKey("engagement"), 0))
                },
                Cell: ({row, value, column}) => {
                    let width = happyRef.current && (happyRef.current.offsetWidth + calmRef.current.offsetWidth + sadRef.current.offsetWidth + angryRef.current.offsetWidth)
                    let processing = get(row, "original.status", "") === STATUS.PROCESSING
                    const color = getColor(TABLE_COLUMNS.ENGAGEMENT, value)
                    return (
                        ["btn", "btn-stream"].includes(get(row, "original.id", "")) ? <div></div> :
                            processing ? <Processing width={width}/> :
                                (get(row, "original.sufficientData", null) ?
                                        <Emotion value={value} color={color} item={row.original}
                                                 trendKey={getEmotionKey("engagement")}/>
                                        :
                                        <Fragment>
                                            <Insufficient width={width}/>
                                            {subRowControls &&
                                            <DeleteVideo style={{position: 'absolute', width, marginLeft: 210}}
                                                         onClick={() => {
                                                             openDeleteVideoDialog(row);
                                                         }}>
                                                {getString("delete_video")}</DeleteVideo>}
                                        </Fragment>
                                )
                    )
                }
            },
            {
                id: "MOOD",
                Header: () => (<div ref={el => {
                    sadRef.current = el;
                    setRefVisible(!!el);
                }}>
                    {getString("mood")}</div>),
                accessor: (originalRow, rowIndex) => {
                    const usePs = useValencePS && get(originalRow, "valence_ps", false)
                    return normalizedPercentage(get(originalRow, usePs || usePs === 0 ? "valence_ps" : "valence", 0))
                }, Cell: ({row, value}) => {
                    const color = getColor(TABLE_COLUMNS.MOOD, value)
                    return (
                        get(row, "original.sufficientData", null) &&
                        <Emotion value={value} color={color} item={row.original} trendKey={getEmotionKey("valence")}/>
                    )
                }
            },
            {
                id: "WELLBEING",
                Header: () => (<div ref={el => {
                    angryRef.current = el;
                    setRefVisible(!!el);
                }}>
                    {getString("wellbeing")}</div>),
                accessor: (originalRow, rowIndex) => {
                    return normalizedPercentage(get(originalRow, "wellbeing", 0))
                },
                Cell: ({row, value}) => {
                    const color = getColor(TABLE_COLUMNS.WELLBEING, value)
                    return (
                        ["btn", "btn-stream"].includes(get(row, "original.id", "")) ? <div></div> :
                            get(row, "original.sufficientData", null) &&
                            <Emotion value={value} color={color} item={row.original}
                                     trendKey={getEmotionKey("wellbeing")}/>
                        // <Wellbeing value={value} />
                    )
                }
            },
            {
                id: "STRESS",
                Header: () => (<div ref={el => {
                    calmRef.current = el;
                    setRefVisible(!!el);
                }}>
                    {getString("stress")}</div>),
                accessor: (originalRow, rowIndex) => {
                    return normalizedPercentage(get(originalRow, "stress", 0))
                },
                Cell: ({row, value}) => {
                    const color = getColor(TABLE_COLUMNS.STRESS, value)
                    return (
                        ["btn", "btn-stream"].includes(get(row, "original.id", "")) ? <div></div> :
                            get(row, "original.sufficientData", null) &&
                            <Emotion value={value} color={color} item={row.original}
                                     trendKey={getEmotionKey("stress")}/>
                        // <Stress value={value} />
                    )
                }
            },
        ], [rowsIcon, Header, currentLanguage]
    )

    if(addTimeCol){
        let timeCol = {
            id: addTimeCol.header,
            Header: getString(`${addTimeCol.header}`),
            accessor: (originalRow) => {
                return moment(get(originalRow, addTimeCol.value, "")).format("HH:mm")
            },
            Cell: ({row, value}) => {
                return ["btn", "btn-stream"].includes(get(row, "original.id", "")) ? <div></div>
                    : (<Minutes value={value} item={row.original}/>)
            },
            sortType: timeSort
        }
        columns.splice( 2, 0, timeCol );
    }

    contentType && features.showType && columns.unshift({
        id: "TYPE",
        accessor: (originalRow, rowIndex) => {
            return `${getString(`${get(originalRow, "contentType", "")}`)} ${get(originalRow, "comment", null)}`
        },
        Cell: ({row, value, rows}) => {
            return (
                <Type row={row.original} updateTableData={updateTableData} rows={rows} updateTags={updateTags}/>
            )
        }
    })

    features.contentLayer && columns.push({
        id: "EXPANDER",
        Cell: ({row}) => {
            let sufficient = calcSufficientData(row.original, columnValue)
            return (
                sufficient && row.canExpand ? (
                    <span>
                        <img src={getAsset("icons/arrow_bold_orange.svg")}
                             style={{transform: row.isExpanded ? "rotate(270deg)" : "rotate(90deg)"}}
                        />
                    </span>
                ) : null
            )
        }
    })

    const getRowOverTimeChart = async (sessionId, contentId, rowId) => {
        getTableOverTimeData({sessionId, contentId, rowId})
    }

    const getRowVideo = async (videoData, rowId) => {
        const houseId = get(currentHouse, "id", "")
       // console.log("getRowVideo", {videoData, rowId, houseId})
        getTableVideoData({videoData, rowId, houseId})
    }

    const tableData = React.useMemo(
        () => data,
        [data]
    )

    const tableInstance = useTable({
        columns, data: tableData, initialState:
            sortBy ? {
                sortBy: [
                    {
                        id: sortBy,
                        desc: isDesc
                    }
                ]
            } : {}
    }, useGlobalFilter, useSortBy, useExpanded)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state
    } = tableInstance

    useEffect(() => {
        uploadButton ? pinUploadButton(rows) : (pinnedResidents ? pinResidents(rows) : setTableRows(rows))
    }, [rows])


    const TableHeaderWrapper = () => {
        return (
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}
                >
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                                textAlign: 'center',
                                border: 'none',
                                color: column.isSorted ? colors.lipstick : '#909090',
                                fontWeight: column.isSorted ? "800" : "700",
                                fontFamily: fonts.OpenSans,
                                fontSize: 13,
                                letterSpacing: 1.12,
                                cursor: "pointer"
                            }}
                        >
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <div style={column.id === colData[1].header ? {
                                textAlign: colData[1].textAlign,
                                margin: colData[1].margin
                            } : {}}>
                                {
                                    column.id === 'TYPE' || column.id === 'EXPANDER' ? null : column.isSorted
                                        ? column.isSortedDesc
                                            ? <img src={getAsset("icons/arrow_blue.svg")}/>
                                            : <img style={{transform: "rotate(180deg)"}}
                                                   src={getAsset("icons/arrow_blue.svg")}/>
                                        : <img src={getAsset("icons/arrow_blue.svg")}/>
                                }
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
        )
    }

    const getCellStyle = (cell) => {
        let cellStyle = {}
        if (uploadButton) {
            let rowId = get(cell, "row.original.id", "");
            if (rowId === "btn") {
                cellStyle = {backgroundColor: get(cell, "row.original.bgcolor", "")}
            } else if (rowId === "btn-stream") {
                cellStyle = {backgroundColor: get(cell, "row.original.bgcolor", "")}
            }
        } else {
            if (pinResidents) {
                if (get(cell, "row.original.type", "") === RESIDENT_TYPE.GROUP || get(cell, "row.original.type", "") === RESIDENT_TYPE.CARE) {
                    if (get(cell, "column.id", "") === colData[0].header) {
                        cellStyle = {backgroundColor: get(cell, "row.original.bgcolor", "")}
                    }
                    if (get(cell, "column.id", "") === colData[1].header) {
                        cellStyle = {
                            backgroundColor: get(cell, "row.original.bgcolor", ""),
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                        }
                    }
                }
            }
        }

        return cellStyle
    }

    const calcExtendableRow = (row) => {
      //  console.log("calcExtendableRow", {row, unitCount: _.get(row, "unitCount", 0), hasNext})
        let checkupSession = isCheckup(row)
        if (checkupSession) {
            return false
        }
        if (!hasNext) {
            return true
        }
        return false
    }

    const onRowClicked = (row, sufficient, extendable, checkupSession) => {
       // console.log("onRowClicked extendable",extendable, row.original, row)
        let rowId = get(row, "original.id", "");
        if (rowId === "btn") {
            setUploadVideoPopup(true)
        } else if (rowId === "btn-stream") {
            setVideoStreamPopup(true)
        } else {
            if (sufficient) {
                if (checkupSession) {
                    return
                }
                if (!extendable) {

                    onScreenChange(row.original)
                } else {
                    if (row.canExpand && features.contentLayer) {
                        row.getToggleRowExpandedProps({}).onClick()
                        if (!row.isExpanded) {
                            const rowId = get(row, "id", "")
                            if (get(currentHouse, "type", "") === HOUSE_TYPES.realtime_video) {
                                getRowVideo(row.original, rowId)
                                setTableOvertime({rowId, data: get(row.original, "overTime", "")})
                            } else if (get(currentHouse, "type", "") === HOUSE_TYPES.upload) {
                                getRowVideo(row.original, rowId)
                                setTableOvertime({rowId, data: get(row.original, "overTime", "")})
                            } else {
                                getRowOverTimeChart(get(row.original, "sessionId", ""), get(row.original, "contentId", ""), rowId)
                            }
                        }
                    }
                }
            }
        }

        return
    }

    const TableBodyWrapper = () => {
        return (
            <tbody {...getTableBodyProps()}>

            {tableRows.map((row, i) => {

                let sufficient = calcSufficientData(row.original, columnValue)
                let checkupSession = isCheckup(row.original)
                let extendable = calcExtendableRow(row.original)
             //   console.log("TableBodyWrapper extendable",extendable, row.original)

                let rowId = get(row, "original.id", "");
                if (!showInsufficientData && !sufficient && !["btn", "btn-stream"].includes(rowId)) {
                    return
                }

                row.canExpand = extendable
                console.log("row.canExpand", row.canExpand)
                prepareRow(row)
                return (
                    <Fragment key={i}>
                        <tr className="tbl-row" {...row.getRowProps()}
                            onClick={() => onRowClicked(row, sufficient, extendable, checkupSession)}
                            style={{padding: 15}}>
                            {row.cells.map(cell => {
                                const cellStyle = getCellStyle(cell)
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={cellStyle}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                            {!extendable && !checkupSession && <td style={{textAlign: 'right', padding: 0}}>
                                {sufficient && <NextScreenArrow src={getAsset("icons/arrow_bold_orange.svg")}/>}
                            </td>}
                        </tr>
                        {row.isExpanded && renderSubRow(row.id, row.original, row)}
                    </Fragment>
                )
            })}
            </tbody>
        )
    }

    const onOvertimeEmotionClick = (emotion, rowId) => {
        const selectedButtons = selectedOvertimeButtons[rowId]
        let i = findIndex(selectedButtons, (value) => {
            return value === emotion
        });

        let update = _.cloneDeep(selectedButtons)

        if (!selectedButtons || !selectedButtons.length) {
            update.push(emotion)
        } else {
            if (i === -1) {
                update = [emotion]
            } else {
                update.splice(i, 1);
            }
        }

        setSelectedOvertimeButtons({id: rowId, data: update})
    }

    const tooltipLabelFormatter = (payload, data, x) => {
        if (data[payload] && data[payload].metadata) {
            return data[payload].metadata.title
        }
        if (x.formatter) {
            return x.formatter(payload)
        }
        return payload;
    }

    const deleteUploadedVideo = async (videoId, fileOnly) => {
        try {
            //todo: delete from table
            if (videoId) {
                await deleteVideo(videoId)
            }
        } catch (e) {
            console.log("error deleting video", e)
        }
    }

    const editVideoName = async (videoName, videoId,) => {
        try {
            if (videoId) {
                editTableRow({videoId, videoName})
                await editVideo(videoId, videoName)
            }
        } catch (e) {
            console.log("error updating video", e)
        }
    }

    const renderRowNameInput = ({value, setValue}) => {
        return <input
            value={typeof value === 'object' ? "" : value}
            onChange={event => {
                setValue(event.target.value);
            }}
            type="text"
            autoFocus
        />
    }

    const renderSubRowControls = (originalRow, rowId) => {
        return <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginRight: 25,
            marginLeft: 65
        }}>
            <VideoNameContainer>
                <EditableField
                    value={originalRow.name ? originalRow.name :
                        <div style={{width: 100, height: 20, marginLeft: -10}}></div>}
                    renderInput={renderRowNameInput}
                    onSave={(value) => {
                        editVideoName(value, get(originalRow, "videoId", null))
                    }}
                    showButtons={true}/>
                <img src={getAsset("icons/edit_icon.svg")} style={{margin: 5}}/>
            </VideoNameContainer>
            <DeleteVideo onClick={() => {
                setDeleteVideoPopupOpen(true);
                setCurrentSubRow({originalRow, rowId})
            }}>{getString("delete_video")}</DeleteVideo>
        </div>
    }

    const handleExportRowClick = async (originalRow) => {
        let formattedStartDate = startDate.format(REQUEST_DATE_FORMAT)
        let formattedEndDate = endDate.format(REQUEST_DATE_FORMAT)
        try {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            switch (currentScreen) {
                case SCREENS.SESSIONS:
                    await exportSessionOvertime(originalRow.sessionId, timeZone)
                    break
                default:
                    let fullFileNameFormat = originalRow && originalRow.HouseId === 569
                 //   console.log("handleExportRowClick", {originalRow, fullFileNameFormat})
                    await exportVideoData(formattedStartDate, formattedEndDate, timeZone, originalRow, fullFileNameFormat)
            }


        } catch (e) {
            console.error("error exporting video data", e)
        }
    }

    const getOverTimeDataKeyForContext = () => {
        switch (currentScreen) {
            case SCREENS.CONTENT:
                return {
                    key: "index", formatter: (index) => {
                        return format(index)
                    }
                }
            case SCREENS.SESSIONS:
                return {
                    key: "index", formatter: (index) => {
                        return formatSecondsToTimeString(index)
                    }
                }
            default:
                return {key: "index"}
        }
    }

    const handleVideoPreviewClick = (originalRow, rowId) => {
        setIsOpenVideoPreview(true);
        setVideoPreview(originalRow);
        setVideoTracker(originalRow.tracker)
       // console.log("video preview", originalRow)
      //  console.log("tableVideo", tableVideo)
    }

    const renderRowOvertimeChart = (rowId, originalRow, row) => {
        let selectedButtons = selectedOvertimeButtons[rowId] || [OVER_TIME_BUTTONS.WELLBEING]
        let modified, shouldModify = false, domain = [0, 100]

        let buttons = OVER_TIME_BUTTONS

        let houseType = get(currentHouse, "type", null);
        if ([HOUSE_TYPES.realtime_video, HOUSE_TYPES.upload].includes(houseType)) {
            buttons = {
                ...OVER_TIME_BUTTONS,
                ...OVER_TIME_EMOTIONS_BUTTONS
            }
        }

        let videoPreview = HOUSE_TYPES.upload === houseType;

        const showExport = exportInRow || row.canExpand
       // console.log("showExport", showExport)

        async function exportClickHandler() {
            await handleExportRowClick(originalRow);
        }

        return <div style={{
            display: 'flex',
            alignSelf: 'center',
            height: '100%',
            width: '100%',
            paddingTop: 12,
            paddingBottom: 27,
            paddingRight: 15
        }}>
            <div style={{height: '100%', width: '100%', marginRight: 17, display: 'flex'}}>
                {loadingRowOvertime[rowId] ? <Lottie
                    options={defaultOptions}/> : <div style={{height: '100%', width: '100%'}}>
                    {subRowControls && renderSubRowControls(originalRow, rowId)}
                    <OverTimeChart
                        x={getOverTimeDataKeyForContext()}
                        y={{key: "value"}}
                        data={shouldModify ? modified : (tableOvertime[rowId] || [{}])}
                        selectedEmotions={selectedButtons}
                        tooltipLabelFormatter={tooltipLabelFormatter}
                        domain={domain}

                    />
                </div>
                }
            </div>

            {/* {videoInExpander && renderSubRowVideo(rowId, originalRow)} */}

            <div style={{gap: exportInRow ? 10 : 25, display: 'flex', flexDirection: 'column', marginRight: 15}}>
                {showExport && <Button
                    style={exportButtonStyle}
                    onClick={exportClickHandler}>
                    <ExportText>{getString("export")}</ExportText>
                </Button>}
                {videoPreview && (
                    <Button
                        style={exportButtonStyle}
                        onClick={()=> handleVideoPreviewClick(originalRow)}>
                        <ExportText>{getString("show_video")}</ExportText>
                    </Button>
                )}
                {Object.keys(buttons).map((key, i) =>
                    buttons[key] !== OVER_TIME_BUTTONS.INTEREST && <OverTimeButton
                        style={Object.assign(selectedButtons.includes(buttons[key]) ? {backgroundColor: colors.blue} : {})}
                        onClick={() => {
                            onOvertimeEmotionClick(buttons[key], rowId)
                        }}>
                        {getString(buttons[key])}
                    </OverTimeButton>)}
            </div>

            {isOpenVideoPreview && (
                <VideoPreviewPopup
                    videoFile={tableVideo[rowId]}
                    tracker={originalRow.tracker}
                    name={originalRow.name}
                    isOpen={isOpenVideoPreview}
                    closeHandler={() => setIsOpenVideoPreview(false)}
                    exportClickHandler={exportClickHandler}
                    deleteClickHandler={(fileOnly) => {openDeleteVideoDialog({original: originalRow, id: rowId, fileOnly})}}
                    renderRowNameInput={renderRowNameInput}
                    editName={(value)=>{ editVideoName(value, get(originalRow, "videoId", null))}}
                />
            )}

        </div>
    }

    const renderSubRow = (rowId, originalRow, row) => {
        return <tr style={{
            height: get(currentHouse, "type", null) === HOUSE_TYPES.realtime_video ? 450 : 480,
            position: 'relative'
        }}>
            <td>
                <SubRowContainer>
                    {renderRowOvertimeChart(rowId, originalRow, row)}
                </SubRowContainer>
            </td>
        </tr>
    }

    const closeUploadVideoPopup = async (data) => {
        if (data) {
            const {name, videoDuration, videoFile, date, option} = data
            if (get(currentHouse, "type", "") === HOUSE_TYPES.upload) {
                const tempId = Date.now()
                try {
                    pushToTableData({tempId, name, seconds: videoDuration, videoDate: date, status: STATUS.PROCESSING})
                    const houseId = get(currentHouse, "id", null)
                    setUploadingVideo(true)
                    console.log("uploading video")
                    await processVideo({houseId, videoData: data, tempId})
                    setUploadingVideo(false)
                } catch (e) {
                    console.log("error closeUploadVideoPopup", e)
                    setUploadingVideo(false)
                }
                setUploadVideoPopup(false)
            } else {
                setUploadVideoPopup(false)
                setVideoAnalyzePopupTitle(name || "")
                setVideoFile(videoFile)
                setFaceApiModel(option)
                setVideoDate(date)
                setVideoDuration(videoDuration)
                setVideoTracker([])
                setVideoAnalyzePopupOpen(true)
            }


        }else {
            setUploadVideoPopup(false)
        }
    }

    const closeVideoStreamPopup = async (data) => {
        setVideoStreamPopup(false)
        if (data) {
            const {name, videoDuration, date, option} = data
            setVideoAnalyzePopupTitle(name || "")
            setFaceApiModel(option)
            setVideoDate(date)
            setVideoTracker([])
            setVideoDuration(videoDuration)
            setVideoAnalyzePopupOpen(true)
            setVideoAnalyzeForLiveStream(true)

        }
    }

    const deleteVideoPopupOkay = () => {
        const videoId = get(currentSubRow, "originalRow.videoId", null)
        const fileOnly = get(currentSubRow, "originalRow.fileOnly", false)
        closeDeleteVideoPopup()
        if(!fileOnly) {
            removeFromTable(videoId)
        }
        deleteUploadedVideo(videoId, fileOnly)
    }

    const closeDeleteVideoPopup = () => {
        setDeleteVideoPopupOpen(false)
        setCurrentSubRow({})
    }

    const handleCloseVideoAnalyzePopup = () => {
        setVideoAnalyzePopupOpen(false)
    }

    const handleInsufficientDataMode = async (value) => {
        setShowInsufficientData(value)
        await setInsufficientDataMode(value)
    }

    return (
        <Styles>
            <Header>
                <div style={{display: "flex", alignItems: "baseline"}}>
                    <Title>{getString(`${headerTitle}`)}</Title>
                    <Count>{data.length} {getString("total")}</Count>
                </div>

                <div style={{display: "flex", flexDirection: 'row'}}>
                    {DataToShowCheckBox({value: showInsufficientData, setValue: handleInsufficientDataMode})}
                    <div>
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            currPlaceholder={currPlaceholder}
                        />
                    </div>
                </div>
            </Header>
            <table {...getTableProps()} style={{
                borderCollapse: "separate",
                borderSpacing: "0 1em"
            }}>
                <TableHeaderWrapper/>
                <TableBodyWrapper/>
            </table>

            {uploadVideoPopup && <VideoUploadPopup
                isOpen={uploadVideoPopup}
                closeHandler={closeUploadVideoPopup}
                title={getString("upload_video")}
                description={getString("video_upload_popup_description")}
                loading={uploadingVideo}
                loader={loader}
            />}

            {videoStreamPopup && <VideoUploadPopup
                isOpen={videoStreamPopup}
                closeHandler={closeVideoStreamPopup}
                title={getString("video_stream_popup_title")}
                description={getString("video_stream_popup_description")}
                hideFile={true}
            />}

            {deleteVideoPopupOpen && <Popup
                closeOnDocumentClick={true}
                isOpen={deleteVideoPopupOpen}
                closeHandler={closeDeleteVideoPopup}
                title={getString("delete_video_popup_title")}
                description={getString("delete_video_popup_message")}
                footerButtons={[{label: getString("okay_button"), onPress: (fileOnly) => deleteVideoPopupOkay(fileOnly)}]}
            />}

            {videoAnalyzePopupOpen && <VideoAnalyzePopup
                closeOnDocumentClick={false}
                isOpen={videoAnalyzePopupOpen}
                closeHandler={handleCloseVideoAnalyzePopup}
                title={videoAnalyzePopupTitle}
                date={videoDate}
                videoFile={videoFile}
                videoDuration={videoDuration}
                faceApiModel={faceApiModel}
                videoAnalyzeForLiveStream={videoAnalyzeForLiveStream}
                houseId={currentHouse.id}
            />
            }
        </Styles>
    )
}

const mapDispatchToProps = {
    setSelectedOvertimeButtons,
    setShowInsufficientData,
    setTableOvertime,
    getTableOverTimeData,
    getTableVideoData,
    removeFromTable,
    processVideo,
    pushToTableData,
    editTableRow,
    initVideoReady,
    setVideoTracker
};

const mapStateToProps = ({table, house, date, video}) => ({
    currentHouse: house.currentHouse,
    data: table.tableData,
    showInsufficientData: table.showInsufficientData,
    headerTitle: table.headerTitle,
    currPlaceholder: table.currPlaceholder,
    hasNext: table.hasNext,
    columnValue: table.columnValue,
    rowsIcon: table.rowsIcon,
    colData: table.colData,
    isShowSeconds: table.isShowSeconds,
    isDesc: table.isDesc,
    normalizeDataBySeconds: table.normalizeDataBySeconds,
    sortBy: table.sortBy,
    contentType: table.contentType,
    showLastSeen: table.showLastSeen,
    pinnedResidents: table.pinnedResidents,
    tableOvertime: table.tableOvertime,
    tableVideo: table.tableVideo,
    selectedOvertimeButtons: table.selectedOvertimeButtons,
    loadingRowOvertime: table.loadingRowOvertime,
    uploadButton: table.uploadButton,
    videoInExpander: table.videoInExpander,
    subRowControls: table.subRowControls,
    exportInRow: table.exportInRow,
    startDate: date.startDate,
    endDate: date.endDate,
    useValencePS: table.useValencePS,
    videoReady: video.videoReady
});

export default connect(mapStateToProps, mapDispatchToProps)(TableData);

TableData.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        calmness: PropTypes.number,
        emotional: PropTypes.number,
        energy: PropTypes.number,
        happiness: PropTypes.number,
        sessions: PropTypes.number,
        valence: PropTypes.number,
        resident: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            photoUrl: PropTypes.string
        })
    }))
};


const BlackVIcon = styled.img`
    width: 17px;
    height: 17px;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
`

const Header = styled.div`
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            `

const SubRowContainer = styled.div`
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: white;
            border-radius: 8.44px;
            display: flex;
            justify-content: center;
            `

const VideoNameContainer = styled.div`
            display: flex;
            flex-direction: row;
            margin-bottom: 4px;
            `

const VideoName = styled.div`
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            align-items: center;

            color: #2E384D;
            `

const DeleteVideo = styled.div`
            margin-top: 5px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            justify-content: center;
            color: #CC1D6C;
            cursor: pointer;
            text-transform: uppercase;
            `

const Title = styled.div`
            font-family: Rubik;
            font-style: normal;
            font-weight: 300;
            font-size: 1.75em;
            line-height: 1.25em;
            /* identical to box height, or 114% */

            color: #2E384D;
            `

const Count = styled.div`
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375em;
            line-height: 1.375em;
            /* identical to box height, or 147% */
            color: #8798AD;
            margin-left: 0.625em;
            `

const CellContainer = styled.div`
            display: flex;
            align-items: center;
            margin-left: ${props => props.marginLeft};
            justify-content: ${props => props.contentPositioning};

            @media print{
                display: initial;
    }
            `

const CellContent = styled.span`
            //   font-family: Rubik;
            //   font-style: normal;
            //   font-weight: 500;
            //   font-size: 15px;
            //   line-height: 18px;
            //   margin-left: 22px;

            color: #2E384D;

            font-family: Open Sans;
            font-style: normal;
            font-weight: 800;
            font-size: 0.9375em;
            line-height: 1.25em;
            display: flex;
            align-items: center;
            margin-left: 0.9375em;

            @media print{
                width: 55px;
            height: 55px;
            display: block;
            margin-left: 40px;
            margin-top: -30px;
            margin-right: -38px;
  }
            `



const NextScreenArrow = styled.img`
            margin-right: 1.25em;
            margin-left: -1.5625em;
            vertical-align: middle;
            `


const Styles = styled.div`
            margin-top: 2.75em;

            /* This is required to make the table full-width */
            display: block;
            max-width: 100%;

            /* This will make the table scrollable when it gets too small */

            .tableWrap {
                display: block;
            max-width: 100%;
            //overflow-x: scroll;
            // overflow-y: hidden;
            border-bottom: 1px solid black;
  }

            table {
                /* Make sure the inner table is always as wide as needed */
                width: 100%;
            border-spacing: 0 18px;
            margin-top: 1.4375em;
            //max-height: 6.25em;
            position: relative;
            //   border-collapse: collapse;
            //border-collapse: collapse !important;
            tbody {
                tr {
                background: #fff;
            border-radius: 10px;
            border: 1px solid rgba(46, 91, 255, 0.08);
            padding: 1.25em;
      }
    }

            th {
                //position: sticky;
                //top: 0; /* Don't forget this, required for the stickiness */
                background - color: #f4f6fc;
            z-index: 50;
    }

            th,
            td {
                margin: 0;
            padding: 0.5rem;

            /* The secret sauce */
            /* Each cell should grow equally */
            width: 1%;
            /* But "collapsed" cells should be as small as possible */

            &.collapse {
                width: 0.0000000001%;
    }

            :last-child {
                border - right: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
      }

            :first-child {
                border - top - left - radius: 10px;
            border-bottom-left-radius: 10px;
      }
    }
  }

            .pagination {
                padding: 0.5rem;
  }

            .tbl-row {
                transition: box-shadow .3s;
            cursor: pointer;
  }
            .tbl-row:hover {
                box - shadow: 0 0 0.6875em rgba(33,33,33,.2);
  }

            @media print {
    .tbl - head {
                display: table-header-group;
    }
            .tbl-body {
                display: table-row-group;
    }
            .tbl-row {
                display: inline-block;
            width: 100%;
            page-break-inside: avoid;
            padding: 10px !important;
            position: relative;
            right: 0.625em;
    }
  }
            `

//get array of hh:mm:ss:xx strings from duration and fps
const getTimeArray = (duration, fps) => {
    let timeArray = [];
    let time = 0;
    let fpsDuration = 1 / fps;
    while (time < duration) {
        timeArray.push(formatTime(time));
        time += fpsDuration;
    }
    return timeArray;
}

// format seconds to hh:mm:ss.xxx string
const formatTime = (seconds) => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);
    let x = Math.floor((seconds % 1) * 100);
    return `${h}:${m}:${s}.${x}`;
}

//convert hh:mm:ss.xxx string to seconds
const stringToSeconds = (string) => {
    let parts = string.split('.');
    let milliseconds = parts[parts.length - 1]
    parts = parts[0].split(":")
    let seconds = 0;
    seconds += parseInt(parts[0]) * 3600;
    seconds += parseInt(parts[1]) * 60;
    seconds += parseInt(parts[2]);
    seconds += parseInt(milliseconds) / 100;
    return seconds;
}
