import React, { Component } from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import DetectionSquare from "./DetectionSquare"
import { getString } from "../utils/translations"
import * as faceapi from "face-api.js";
import { VIDEO_OPTIONS, OVER_TIME_BUTTONS, LEVELS, HOUSE_TYPES, OVER_TIME_EMOTIONS_BUTTONS } from "../constants"
import { detectEmotions} from "../utils/bioFeedback/faceApi"

import _, { get, findIndex } from "lodash";
import OverTimeChart from './Charts/OverTime';
import colors from "../styles/colors"
import VideoFaceDetector from "./VideoFaceDetector";
import VideoStream from "./VideoStream";

import { format } from "../views/Dashboard/TableData/utils"
import { setVideoTracker } from "../redux/videos"

import { connect } from "react-redux";

import {
    calcValenceTracker, calcEnergyTracker, calcStress,
    calcWellbeing, calcInterest, calcEngagement,  calcInterestLevel,
    calcEngagementLevel,
    normalizeValue,
    calcWellbeingLevel,
    calcStressLevel
} from "../utils/bioFeedback"


class Video extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageWidth: 0,
            imageHeight: 0,
            detectionSquareTop: 0,
            detectionSquareLeft: 0,
            currentTime: 0,
            seconds: 0,
            noResultCount: 0,
            detectionsArray: [],
            input: null,
            overtime: [],
            selectedButtons: [OVER_TIME_BUTTONS.WELLBEING],
            videoSrc: null,
            videoPlaying: false,
            videoEnded: false,
            options: this.getOptions(props),
            backgroundAnalyzing: false,
            backgroundProcessingPercentage: 0,
        }

        // this.componentTimeOut = null
        this.videoElement = React.createRef()
        this.shouldDetectTimeOut = null
        this.detectTimeOut = null
        this._isMounted = false;
    }

    getOptions = (props) => {
        const { faceApiModel } = props
        let options
        if (faceApiModel === VIDEO_OPTIONS.multiple) {
            options = new faceapi.SsdMobilenetv1Options({ minConfidence: 0.3 })
        } else {
            options = new faceapi.TinyFaceDetectorOptions({ inputSize: 416, scoreThreshold: 0.3 });
        }
        return options
    }

    componentDidMount() {
        const { videoUrl, videoFile, setAnalyzing } = this.props
        let videoSrc = videoFile ? URL.createObjectURL(videoFile) : videoUrl
        this.setState({ videoSrc })
        this.setState({ videoPlaying: true })
        this._isMounted = true;
        setAnalyzing && setAnalyzing(true)
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { input } = this.state
        const {setAnalyzing, previewMode, videoAnalyzeForLiveStream} = this.props

        const video = document.getElementById('vid');

        if (video) {
            if (!input) {
                this.setState({ input: document.getElementById('vid') })
                this.currentDimensions()
            }
            video.onloadeddata = (event) => {
                console.log("onloadeddata")
                video.play()
            };
            video.onplay = (event) => {
                console.log("onplay")
                this.detectEmotions()
                if (this.state.videoEnded) {
                    this.setState({ videoEnded: false })
                    !previewMode && setAnalyzing && setAnalyzing(false)
                }
            };
            video.onpause = (event) => {
                clearTimeout(this.shouldDetectTimeOut)
                clearTimeout(this.detectTimeOut)
                this.setState({ videoPlaying: false })
                console.log("onpause")
            };
            video.onended = (event) => {
                clearTimeout(this.shouldDetectTimeOut)
                clearTimeout(this.detectTimeOut)
                this.setState({ videoEnded: true, videoPlaying: false })
                setAnalyzing && setAnalyzing(false)
                console.log("onended")
            };
        }

        if(!videoAnalyzeForLiveStream && prevProps.videoAnalyzeForLiveStream){
            clearTimeout(this.shouldDetectTimeOut)
            clearTimeout(this.detectTimeOut)
        }
    }

    componentWillUnmount() {
        console.log("componentWillUnmount")

        clearTimeout(this.shouldDetectTimeOut)
        clearTimeout(this.detectTimeOut)

        this._isMounted = false;
        //clearTimeout(this.componentTimeOut);
    }

    currentDimensions = () => {
        if (this.videoElement.current) {
            this.setState({
                imageWidth: this.videoElement.current.offsetWidth,
                imageHeight: this.videoElement.current.offsetHeight,
                detectionSquareTop: this.videoElement.current.offsetTop,
                detectionSquareLeft: this.videoElement.current.offsetLeft
            })
        }
    }

    shouldDetectEmotions = () => {
        const { videoPlaying } = this.state
        if (videoPlaying) {
            return true
        }
        return false
    }

    renderOvertimeButtons = (buttons) => {

        const { selectedButtons } = this.state

        return <div style={{ display: 'flex', flexDirection: 'column', height: "100%", justifyContent: 'space-around' }}>
            {
                Object.keys(buttons).map((key, i) =>
                    <OverTimeButton
                        key={key}
                        style={Object.assign(selectedButtons.includes(buttons[key]) ? { backgroundColor: colors.blue } : {})}
                        onClick={() => { this.onOvertimeEmotionClick(buttons[key]) }}>
                        {getString(buttons[key])}
                    </OverTimeButton>)
            }
        </div>
    }

    tooltipLabelFormatter = (payload, data, x) => {
        if (data[payload] && data[payload].metadata) {
            return data[payload].metadata.title
        }
        if (x.formatter) {
            return x.formatter(payload)
        }
        return payload;
    }

    onOvertimeEmotionClick = (emotion) => {

        const { selectedButtons } = this.state

        let i = findIndex(selectedButtons, (value) => { return value === emotion });

        let update = _.cloneDeep(selectedButtons)

        if (!selectedButtons.length) {
            update.push(emotion)
        } else {
            if (i === -1) {
                update = [emotion]
            } else {
                update.splice(i, 1);
            }
        }

        this.setState({ selectedButtons: update })
    }

    getResults = (avg) => {
        const valence = calcValenceTracker(avg)
        const energy = calcEnergyTracker(avg)
        const stress = calcStress(avg)
        const wellbeing = calcWellbeing(avg)
        const interest = calcInterest(avg)
        const engagement = calcEngagement(avg)

        const stressLevel = calcStressLevel(stress)
        const interestLevel = calcInterestLevel(interest)
        const engagementLevel = calcEngagementLevel(engagement)
        const wellbeingLevel = calcWellbeingLevel(wellbeing)

        return {
            valence,
            energy,
            stress,
            wellbeing,
            interest,
            engagement,
            stressLevel,
            interestLevel,
            engagementLevel,
            wellbeingLevel
        }
    }

    detectEmotions = async () => {

        if (!this._isMounted) {
            return;
        }

        const { setVideoTracker, tracker, previewMode, detectionInterval} = this.props
        const { input, options } = this.state

        let widgets

        try {
            // if (!this.shouldDetectEmotions()) {
            //     return this.shouldDetectTimeOut = setTimeout(() => this.detectEmotions(), 500)
            // }

            let beforeDetectEmotions = this.videoElement.current && this.videoElement.current.currentTime
            console.log("beforeDetectEmotions", beforeDetectEmotions)
            const result = await detectEmotions(input, options)
            let afterDetectEmotions = this.videoElement.current && this.videoElement.current.currentTime

          //  console.log("RESULT", result)

           /* if (result && result.length === 0 && beforeDetectEmotions === 0) {//&& afterDetectEmotions === 0
                return this.shouldDetectTimeOut = setTimeout(() => this.detectEmotions(), 50)
            }*/
            let currentTime = this.videoElement.current
                && this.videoElement.current.currentTime

            if (result && result.length) {
                const { noResultCount } = this.state
                let counter = noResultCount
                this.setState({ noResultCount: counter - 1 })

                this.setState({ detectionsArray: [] })

                const detections = []
                const widgetResults = []
                const res = []
                const resWithLevels = []

                let i = 0
                for (const r of result) {

                    widgets = {}
                    let id = i++

                    const { angry, disgusted, fearful, happy, neutral, sad, surprised } = r.expressions
                    let avg = { happiness: happy, neutral, angry, disgusted, surprised, sad, fearful }

                    const results = this.getResults(avg)
                    const { valence, energy, stress, wellbeing, interest, engagement } = results

                    const detectionResult = {
                        id,
                        expressions: { angry, disgusted, fearful, happy, neutral, sad, surprised },
                        detection: {
                            ...r.detection,
                            _imageDims: { ...r.detection._imageDims },
                            _box: { ...r.detection._box }
                        },
                        energy,
                        engagement,
                        interest,
                        stress,
                        valence,
                        wellbeing,
                    }

                    res.push(detectionResult)

                    const { stressLevel, interestLevel, engagementLevel, wellbeingLevel } = results
                    let objWithLevels = Object.assign({}, detectionResult)

                    objWithLevels.stressLevel = stressLevel
                    objWithLevels.interestLevel = interestLevel
                    objWithLevels.engagementLevel = engagementLevel
                    objWithLevels.wellbeingLevel = wellbeingLevel

                    resWithLevels.push(objWithLevels)
                }

                let emotions = this.calcResultAvg(res)
                this.setPlayerState(emotions, currentTime, resWithLevels)

                if(!previewMode){
                    this.saveResults({
                        emotions,
                        currentTime,
                        result: res,
                        tracker
                    })
                }


            } else {
                let { noResultCount } = this.state
                let counter = noResultCount
                if(!previewMode){

                    this.saveResults({
                        emotions: null,
                        currentTime,
                        result: [],
                        tracker
                    })
                }
                this.setState({ noResultCount: counter + 1 })
                if (counter > 3) {
                    this.setState({
                        detectionsArray: [],
                        noResultCount: 0
                    })
                }
                let updatedOvertime = _.cloneDeep(this.state.overtime)

                updatedOvertime.push({
                    index: format(currentTime),
                    mood: null,
                    energy: null,
                    wellbeing: null,
                    engagement: null,
                    interest: null,
                    stress: null,
                    angry: null,
                    disgusted: null,
                    fearful: null,
                    happy: null,
                    neutral: null,
                    sad: null,
                    surprised: null,
                })

                this.setState({ overtime: updatedOvertime })
            }

            if (this.shouldDetectEmotions()) {
                this.detectTimeOut = setTimeout(() => this.detectEmotions(), detectionInterval)
            }
        } catch (e) {
            console.log("error", e)
        }
    }

    setPlayerState = (emotions, currentTime, detectionsArray) => {
        this.pushToOverTime(emotions, currentTime)
        this.setState({ detectionsArray })
    }

    saveResults = ({tracker, currentTime, result, emotions}) => {
        const { setVideoTracker } = this.props

        let updatedTracker = _.cloneDeep(tracker)
        updatedTracker.push({
            currentTime,
            result,
            emotions
        })

        if(this.props.videoAnalyzeForLiveStream){
            console.log("saveResults", updatedTracker)
            setVideoTracker(updatedTracker)
        }
    }

    calcResultAvg = (result = []) => {
        const arrayAvg = (arr) => {
            if (!arr.length) {
                return 0;
            }
            return arr.reduce((p, c) => p + c, 0) / arr.length
        }

        let avgArr = {
            neutral: [],
            happiness: [],
            sad: [],
            angry: [],
            fearful: [],
            disgusted: [],
            surprised: [],
            energy: [],
            engagement: [],
            interest: [],
            stress: [],
            valence: [],
            wellbeing: [],
        }

        result.map((item) => {
            avgArr.neutral.push(item.expressions.neutral)
            avgArr.happiness.push(item.expressions.happy)
            avgArr.sad.push(item.expressions.sad)
            avgArr.angry.push(item.expressions.angry)
            avgArr.fearful.push(item.expressions.fearful)
            avgArr.disgusted.push(item.expressions.disgusted)
            avgArr.surprised.push(item.expressions.surprised)
            avgArr.energy.push(item.energy)
            avgArr.engagement.push(item.engagement)
            avgArr.interest.push(item.interest)
            avgArr.stress.push(item.stress)
            avgArr.valence.push(item.valence)
            avgArr.wellbeing.push(item.wellbeing)
        })

        let avg = {
            neutral: arrayAvg(avgArr.neutral),
            happy: arrayAvg(avgArr.happiness),
            sad: arrayAvg(avgArr.sad),
            angry: arrayAvg(avgArr.angry),
            fearful: arrayAvg(avgArr.fearful),
            disgusted: arrayAvg(avgArr.disgusted),
            surprised: arrayAvg(avgArr.surprised),
            energy: arrayAvg(avgArr.energy),
            engagement: arrayAvg(avgArr.engagement),
            interest: arrayAvg(avgArr.interest),
            stress: arrayAvg(avgArr.stress),
            valence: arrayAvg(avgArr.valence),
            wellbeing: arrayAvg(avgArr.wellbeing),
        }

        return avg;
    }

    pushToOverTime = (data, currentTime) => {
        const { overtime } = this.state
        let updatedOvertime = _.cloneDeep(overtime)

        const { valence, energy, wellbeing, engagement, interest, stress,
            angry, disgusted, fearful, happy, neutral, sad, surprised } = data

        updatedOvertime.push({
            index: format(currentTime),
            mood: normalizeValue(valence),
            energy: normalizeValue(energy),
            wellbeing: normalizeValue(wellbeing),
            engagement: normalizeValue(engagement),
            interest: normalizeValue(interest),
            stress: normalizeValue(stress),
            angry: normalizeValue(angry),
            disgusted: normalizeValue(disgusted),
            fearful: normalizeValue(fearful),
            happy: normalizeValue(happy),
            neutral: normalizeValue(neutral),
            sad: normalizeValue(sad),
            surprised: normalizeValue(surprised),
        })

        this.setState({ overtime: updatedOvertime })
    }

    renderDetectionSquare = (height, width, vidCurrTime, data) => {

        const { detectionsArray } = this.state
        const result = _.cloneDeep(detectionsArray)
        // const result = data.filter(d => d.currentTime === vidCurrTime) //from state - video upload processed in server

        return (
            result.map(r => { //d
                // let r = (d.result[0])
                let dominantEmotion = this.getDominantEmotion(r)
                let emphasizeDetection = true

                return <DetectionSquare
                    key={r.id}
                    emphasize={emphasizeDetection}
                    percentage={dominantEmotion.value}
                    label={getString(dominantEmotion.label)}
                    progressColor={dominantEmotion.color}
                    top={(r.detection._box._y / (r.detection._imageDims._height / height)) - 15}
                    left={r.detection._box._x / (r.detection._imageDims._width / width) - 10}
                    width={r.detection._box._width / (r.detection._imageDims._width / width) + 15}
                    height={(r.detection._box._height / (r.detection._imageDims._height / height)) + 5}
                    extendedDetectionSquare={true}
                    stressLevel={r.stressLevel}
                    interestLevel={r.interestLevel}
                    engagementLevel={r.engagementLevel}
                    wellbeingLevel={r.wellbeingLevel}
                />
            }
            )
        )
    }

    getDominantEmotion = (detection) => {
        const expressionsArr = []

        for (const expression in detection.expressions) {
            if (typeof detection.expressions[expression] === "number") {
                const color = this.detectEmotionColor(expression)
                const value = Math.round(detection.expressions[expression] * 100)
                expressionsArr.push({ label: expression, color, value })
            }
        }

        const maxObject = expressionsArr.reduce(function (prev, current) {
            return (prev.value > current.value) ? prev : current
        })

        return maxObject
    }

    detectEmotionColor = (expression) => {
        switch (expression) {
            case 'happy':
                return "#FFBD58"
            case 'neutral':
                return "#6FCF97"
            case 'sad':
                return "#EB5757"
            case 'angry':
                return "#000000"
            case 'disgusted':
                return "#828282"
            case 'fearful':
                return "#BB6BD9"
            case 'surprised':
                return "#56CCF2"
        }
    }

    renderOvertimeChart = () => {
        const { selectedButtons, overtime } = this.state
        const { currentHouse } = this.props

        let modified = _.cloneDeep(overtime)
        let domain = [0, 100]

        let houseType = get(currentHouse, "type", "");
        return (<OverTimeContainer>
            {[HOUSE_TYPES.realtime_video, HOUSE_TYPES.upload].includes(houseType) ?
                this.renderOvertimeButtons(Object.values(OVER_TIME_BUTTONS))
                : this.renderOvertimeButtons([OVER_TIME_BUTTONS.MOOD, OVER_TIME_BUTTONS.ENERGY, OVER_TIME_BUTTONS.WELLBEING])}
            <ChartContainer>
                <OverTimeChart
                    x={{ key: "index" }}
                    y={{ key: "value" }}
                    data={modified || []}
                    selectedEmotions={selectedButtons}
                    tooltipLabelFormatter={this.tooltipLabelFormatter}
                    domain={domain}
                    showRangePicker={false}
                />
            </ChartContainer>
            {[HOUSE_TYPES.realtime_video, HOUSE_TYPES.upload].includes(houseType) ?
                this.renderOvertimeButtons(Object.values(OVER_TIME_EMOTIONS_BUTTONS))
                : this.renderOvertimeButtons([OVER_TIME_BUTTONS.ENGAGEMENT, OVER_TIME_BUTTONS.INTEREST, OVER_TIME_BUTTONS.STRESS])}
        </OverTimeContainer>)
    }

    renderFooter = () => {
        let percentage = 0
        const video = document.getElementById('vid');
        const { videoEnded, backgroundProcessingPercentage } = this.state
        const {backgroundProcessing} = this.props
        if(backgroundProcessingPercentage){
            percentage = backgroundProcessingPercentage;
        }
        else if (video) {
            let videoDuration = video.duration
            let currentTime = this.videoElement.current && this.videoElement.current.currentTime
            if (!isNaN(videoDuration)) {
                percentage = (currentTime / videoDuration) * 100
            }
        }

        let title = "";
        if(this.props.videoAnalyzeForLiveStream){
            percentage = 100
            title = getString("analyzing")
        }else if(percentage === 0){
            title = getString("start_video_analyze")
        }else if(backgroundProcessing){
            title = `${getString("processing_video")} ${backgroundProcessingPercentage}%`
        }

        return (<Footer>
            <LoaderTitle>
                {title}
            </LoaderTitle>
            <Loader progress={`${(this.progressLimit(0, percentage, 100) / 100) * 100}%`} />
        </Footer >)
    }

    progressLimit = (min, value, max) => {
        return Math.min(Math.max(min, value), max);
    }

    updateInput = (input) => {
        this.setState({ input })
    }

    streamStarted = async () => {
        const { input } = this.state
        console.log("streamStarted", input)
        this.detectEmotions()
    }

    previewStarted = async () => {
        const {tracker} = this.props

        console.log("previewStarted", tracker)

    }

    updateBackgroundProcessing = (currentTime, duration) => {
        this.setState({
            backgroundProcessingPercentage: duration ? Math.round((currentTime / duration) * 100) : 0
        })
    }

    render() {

        const { videoSrc, detectionsArray } = this.state
        const { setVideoTracker, tracker, height, videoAnalyzeForLiveStream, previewMode } = this.props

        let imageWidth, imageHeight, detectionSquareTop, detectionSquareLeft

        if (this.videoElement.current) {
            imageWidth = this.videoElement.current.offsetWidth
            imageHeight = this.videoElement.current.offsetHeight
            detectionSquareTop = this.videoElement.current.offsetTop
            detectionSquareLeft = this.videoElement.current.offsetLeft
        }
        let videoMaxHeight = "100%"
        if(height){
            videoMaxHeight = height * .75 - 20 - 45
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: "100%", justifyContent: "space-evenly", paddingBottom: 60 }}>
                <VideoContainer>
                    {videoSrc && <video
                        id="vid"
                        style={{ maxWidth: '100%', maxHeight: videoMaxHeight }}
                        controls={previewMode}
                        autoPlay={false}
                        src={videoSrc}
                        ref={this.videoElement}
                        crossOrigin='anonymous'
                    />}
                    {videoAnalyzeForLiveStream && (
                        <VideoStream
                            streamSuccessHandler={this.streamStarted}
                            width={"100%"}
                            maxHeight={videoMaxHeight}
                            updateInput={this.updateInput}
                            innerRef={this.videoElement}
                        />
                    )}
                    <div style={{ position: 'absolute', top: detectionSquareTop, left: detectionSquareLeft }}>
                        {detectionsArray.length ?
                            this.renderDetectionSquare(imageHeight, imageWidth)//, vidCurrTime, data)
                            : null}
                    </div>
                </VideoContainer>
                {this.renderOvertimeChart()}
                {this.renderFooter()}

                {!previewMode && (
                    <VideoFaceDetector
                        interval={200}
                        src={videoSrc}
                        onFinishDetection={() => {
                            console.log("finish detection", tracker)
                            this.props.setBackgroundProcessing(false)
                        }}
                        options={this.state.options}
                        tracker={tracker}
                        setVideoTracker={setVideoTracker}
                        videoStarted={()=>{this.props.setBackgroundProcessing(true)}}
                        onProgress={({currentTime, duration})=>{
                            this.updateBackgroundProcessing(currentTime, duration)
                        }}

                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ video, house }) => ({
    tracker: video.tracker,
    currentHouse: house.currentHouse
});

const mapDispatchToProps = {
    setVideoTracker
};

Video.propTypes = {
    previewMode: PropTypes.bool,
    detectionInterval: PropTypes.number,
}

Video.defaultProps = {
    previewMode: false,
    detectionInterval: 500,
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);

const VideoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
   // height: 75%;
    position: relative;
`
const OverTimeContainer = styled.div`
            width: 100%;
            height: 25%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-left: 3px;
            padding-right: 3px;
            `

const ChartContainer = styled.div`
            height: 100%;
            width: 100%;
            margin-top: 15px;
            margin-right: 12px;
            margin-left: -23px;
            `

const OverTimeButton = styled.button`
background: #909090;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
// padding-left: 10px;
// padding-right: 10px;

font-family: Open Sans;
font-weight: 900;
font-size: 10px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
text-transform: uppercase;
width: 92px;
height: 18px;

color: #FFFFFF;
cursor: pointer;
border: 0;
outline: 0;
`

const LoaderTitle = styled.div`
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000247;
            text-transform: capitalize;
            `

const Loader = styled.div`
            height: 40px;
            width: ${props => props.progress};
            background: #536EEC;
            transition: width 900ms;
            border-bottom-right-radius: 9px;
            `

const Footer = styled.div`
        width: 100.3%;
        bottom: 0;
        left: 0;
        height: 40px;
        margin: -1px;
        position: absolute;
            `
